import { CrownOutlined, RollbackOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Card, Col, message, Row, Select, Space, Tag } from 'antd';
import { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Header_Line from '../../components/Header_Line';
import { get_accounts, reset_account } from '../../modules/account/actions';
import { reset_category } from '../../modules/category/actions';
import { get_item, reset_item } from '../../modules/item/actions';
import { me } from '../../modules/user/actions';
import { curencyFormat } from '../../utils/help';
import Buy_Account from './Buy_Account';
const { Option } = Select;
const Shop = () => {
  let history = useHistory();
  let { id, type } = useParams();
  const dispatch = useDispatch();
  const _categoryList = useSelector(({ category }) => category.categoryList);
  const _accountList = useSelector(({ account }) => account.accountList);
  const _itemList = useSelector(({ item }) => item.itemList);
  const _buyAccount = useSelector(({ account }) => account.buyAccount);
  const _user = useSelector(({ user }) => user.me.user);

  let initialState = {
    select: null,
    page: 0,
    limit: 1,
    account: null,
    isShowBuyAccount: false,
    isVisibleResultAccount: false,
  };
  const [state, setState] = useState(initialState);
  let select = state.select;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!['accounts', 'items'].includes(type)) {
      message.warning('Không tìm thấy sản phẩm này');
      history.push('/');
    }
  }, []);
  useEffect(() => {
    if (type == 'accounts') {
      if (_accountList.data.length !== 0) {
        let currentAccount = _accountList.data.find((account) => account.id == id);
        if (!currentAccount) {
          message.warning('Không tìm thấy sản phẩm này');
          history.push('/');
        }
        setState({ ...state, select: currentAccount });
      } else if (_accountList.data.length == 0 && _accountList.status) {
        message.warning('Không tìm thấy sản phẩm này');
        history.push('/');
      }
    }
  }, [_itemList, _accountList]);
  const handleGetData = () => {
    if (type == 'accounts') handleGetAccount();
    else if (type == 'items') handleGetItem();
  };

  const handleGetAccount = async () => {
    dispatch(
      get_accounts({
        id: id,
        type: 0,
        limit: state.limit,
        page: state.page,
      })
    );
  };
  const handleGetItem = async () => {
    dispatch(
      get_item({
        id: id,
        type: 1,
        limit: state.limit,
        page: state.page,
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetData();
    return () => {
      dispatch(reset_category({ remove: ['all'] }));
      dispatch(reset_account({ remove: ['all'] }));
      dispatch(reset_item({ remove: ['all'] }));
    };
  }, []);

  const handleBuy = () => {
    if (!_user || !_user?._id) {
      return message.warning('Vui lòng đăng nhập trước khi mua hàng');
    } else {
      setState({ ...state, isShowBuyAccount: true });
    }
  };
  return (
    <div>
      {state.select?._id ? (
        <div>
          <Card style={{ marginBottom: 10 }} bodyStyle={{ paddingBottom: 10 }}>
            <Row>
              <Col xl={6} lg={6} md={24} sm={24} xs={24} style={{ textAlign: 'right', alignSelf: 'center' }}>
                <img
                  width={'100%'}
                  height={180}
                  src={
                    select?.category?.product_type == 0
                      ? select.img
                      : `${process.env.REACT_APP_IMAGE_ENDPOINT}${select.category.img}`
                  }
                />
              </Col>
              <Col
                xl={18}
                lg={18}
                md={24}
                sm={24}
                xs={24}
                style={{
                  padding: !isMobile ? '0px 10px 0px 30px' : '0px 0px 10px 0px',
                }}
              >
                <Row>
                  <Col span={16}>
                    <b>#ID: {select.id}</b>
                    <br />
                    <span>Mô tả: {select.description}</span>
                    <br />
                    {select?.category?.discount !== 0 && select?.category?.discount !== null ? (
                      <>
                        <span
                          style={{
                            textDecorationLine: 'line-through',
                            color: 'rgb(255 31 31 / 80%)',
                          }}
                        >
                          Giá gốc: {curencyFormat(select.price)}
                        </span>
                        <br></br>
                        <span style={{}}>
                          Giảm ({select?.category?.discount}%):{' '}
                          {curencyFormat(select.price - (select.price / 100) * select?.category?.discount)}
                        </span>
                      </>
                    ) : (
                      <>
                        <h3 style={{}}>Giá: {curencyFormat(select.price)}</h3>
                      </>
                    )}
                  </Col>
                  {!isMobile ? (
                    <Col span={8} style={{ textAlign: 'right', alignSelf: 'center' }}>
                      <Space direction='horizontal' style={{ width: '100%', justifyContent: 'right' }}>
                        <Button
                          onClick={() => {
                            history.push(`/shop/accounts/${select?.category?.url}`);
                          }}
                          icon={<RollbackOutlined />}
                          className='color2'
                          style={{ width: isMobile ? '100%' : null }}
                        >
                          Quay Lại Danh Mục
                        </Button>
                        <Button
                          onClick={handleBuy}
                          icon={<ShoppingCartOutlined />}
                          className='color1'
                          style={{ width: isMobile ? '100%' : null }}
                        >
                          Mua Ngay
                        </Button>
                      </Space>
                    </Col>
                  ) : null}
                </Row>

                <Row style={{ marginTop: isMobile ? 0 : 28 }}>
                  <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? 'pdR' : 'wi-100'}>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Danh Mục: {select?.category?.name ? select?.category?.name : 'Không Có'}
                    </Tag>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Tướng: {select.champ ? select.champ : 'Không Có'}
                    </Tag>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Mùa: {select?.season ? select?.season : 'Không Có'}
                    </Tag>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} style={{ width: '100%' }}>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Skin: {select.skin ? select.skin : 'Không Có'}
                    </Tag>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Level: {select.level ? select.level : 'Không Có'}
                    </Tag>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Rank HT: {select.rank_ht ? select.rank_ht : 'Không Có'}
                    </Tag>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? 'pdL' : 'wi-100'}>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Khung: {select.khung ? select.khung : 'Không Có'}
                    </Tag>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Rank: {select.rank ? select.rank : 'Không Có'}
                    </Tag>
                    <Tag
                      style={{
                        width: '100%',
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=''
                    >
                      Khung HT: {select.khung_ht ? select.khung_ht : 'Không Có'}
                    </Tag>
                  </Col>

                  {isMobile ? (
                    <Col span={24} style={{ padding: 2 }}>
                      <Button
                        onClick={() => {
                          history.push(`/shop/accounts/${select?.category?.url}`);
                        }}
                        icon={<RollbackOutlined />}
                        className='color2'
                        style={{ width: '59%', marginRight: '2%' }}
                      >
                        Quay Về Danh Mục
                      </Button>
                      <Button
                        onClick={handleBuy}
                        icon={<ShoppingCartOutlined />}
                        className='color1'
                        style={{ width: '39%' }}
                      >
                        Mua Ngay
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
          </Card>
          <Card>
            <div style={{ textAlign: 'center' }}>
              <Header_Line text={'HÌNH ẢNH TÀI KHOẢN'} textColor='#656565' lineColor='#32c5d2' />
              {select.img_list.map((img) => {
                return (
                  <img
                    style={{ marginBottom: 20 }}
                    width={isMobile ? '100%' : '80%'}
                    src={img}
                  />
                );
              })}
            </div>
          </Card>
        </div>
      ) : null}
      {state.isShowBuyAccount ? <Buy_Account state={state} data={state.select} setState={setState} /> : null}
      {select?._id ? (
        <SweetAlert
          show={state.isVisibleResultAccount}
          success
          showCancel={false}
          confirmBtnText={'Xem Account'}
          confirmBtnBsStyle='danger'
          cancelBtnBsStyle='default'
          title={
            <div>
              <p style={{ marginBottom: '-10px' }}>Đặt hàng thành công!</p>
              <p style={{ fontSize: 20, padding: 20, marginBottom: '-20px' }}>
                Chúc mừng bạn đã mua thành công account #{select?.id}
              </p>
            </div>
          }
          onConfirm={() => {
            dispatch(me());
            setState({ ...state, isVisibleResultAccount: false });
            history.push('/profile/accounts');
          }}
        ></SweetAlert>
      ) : null}
    </div>
  );
};
export default Shop;
