import { Space, Spin, Typography, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDetailGuide } from '../../../modules/general/service';
import DOMPurify from 'isomorphic-dompurify';

export default function GuideView() {
  const [loading, setLoading] = useState(false);
  const [dataGuide, setDataGuide] = useState([]);
  const { id } = useParams();

  const onGetGuides = async (id) => {
    setLoading(true);

    try {
      const res = await getDetailGuide({
        id,
      });
      if (res?.data?.status) {
        setDataGuide(res?.data?.data);
      } else {
        message.error({
          message: res?.data?.msg ?? 'Lấy hướng dẫn chi tiết thất bại',
        });
      }
    } catch (error) {
      message.error(
        error?.response?.data?.msg ?? 'Lấy chi tiết hướng dẫn thất bại'
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      onGetGuides(id);
    }
  }, [id]);

  const richText = useMemo(() => {
    // chống tấn công xss
    const domPurityText = DOMPurify.sanitize(dataGuide?.description);

    return domPurityText;
  }, [dataGuide]);

  return (
    <Spin spinning={loading}>
      <Space
        direction='vertical'
        size={24}
        style={{ width: '100%', margin: '48px 0' }}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '32px',
              textAlign: 'center',
              color: 'red',
            }}
          >
            {dataGuide.title}
          </Typography>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: richText }}
          className='richtext'
        />
      </Space>
    </Spin>
  );
}
