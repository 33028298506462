import { API_PATH } from '../../constants/api.constants';
import { httpClient as request } from '../../utils/Api';
// router.post("/account/add_account", auth, accounts.add_account);
// router.post('/account/change_account_info', auth_valid, accounts.change_account_info)
// router.post("/account/update_account", auth, accounts.update_account);
// router.post("/account/add_account_random", auth, accounts.add_account_random);
// router.post("/account/delete_account", auth, accounts.delete_account);
// router.post("/account/get_accounts", auth, accounts.get_accounts);
// router.post("/account/get_accounts_by_admin", auth, accounts.get_accounts_by_admin);
// router.post("/account/get_accounts_owner", auth, accounts.get_accounts_owner);
// router.post("/account/buy_account", auth, accounts.buy_account);

export const add_account = ({ role, ...data }) => {
  return role === 'provider'
    ? request.post(API_PATH.ADD_ACCOUNT_PROVIDER, data)
    : request.post('/account/add_account', data);
};

export const change_account_info = (payload) => {
  return request.post('/account/change_account_info', payload);
};

export const cancel_request_change_account_info = (payload) => {
  return request.post('/account/cancel_request_change_account_info', payload);
};

export const update_account = ({ role, ...payload }) => {
  if (role === 'provider') {
    return request.post(API_PATH.UPDATE_ACCOUNT_PROVIDER, payload);
  }
  return payload.request_type == 1
    ? request.post('/account/change_owner', payload)
    : request.post('/account/update_account', payload);
};

export const delete_account = ({ role, ...payload }) => {
  return role === 'provider'
    ? request.post(API_PATH.DELETE_ACCOUNT_PROVIDER, payload)
    : request.post('/account/delete_account', payload);
};

export const add_account_random = ({ role, ...payload }) => {
  return role === 'provider'
    ? request.post(API_PATH.ADD_RANDOM_ACCOUNT_PROVIDER, payload)
    : request.post('/account/add_account_random', payload);
};

export const get_accounts = (payload) => {
  return request.post('/account/get_accounts', payload);
};

export const get_accounts_provide = (payload) => {
  return request.post('/account/provider/get_accounts', payload);
};

export const get_accounts_by_admin = ({ role, ...payload }) => {
  return role === 'provider'
    ? request.post(API_PATH.GET_ACCOUNT_PROVIDER, payload)
    : request.post('/account/get_accounts_by_admin', payload);
};

export const get_accounts_owner = (payload) => {
  return request.post('/account/get_accounts_owner', payload);
};

export const buy_account = (payload) => {
  return request.post('/account/buy_account', payload);
};

export const get_historys = (payload) => {
  return request.post('/account/get_historys', payload);
};

export const delete_history = (payload) => {
  return request.post('/account/delete_history', payload);
};

export const confirm_change = (payload) => {
  return request.post('/account/confirm-change-profile', payload);
};

export const update_history = (payload) => {
  return request.post('/account/update_history', payload);
};

export const get_account_price = (payload) => {
  return request.post('/account/get_account_price', payload);
};

export const get_account_info = (payload) => {
  return request.post('/account/get_account_info', payload);
};

export const confirmAccount = (id) => {
  return request.post(API_PATH.CONFIRM_ACCOUNT_PROVIDER(id), {});
};
