import React, { useEffect, useState } from "react";
import { Button, message, Upload, Avatar, Skeleton } from "antd";
import {
  UploadOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import md5 from "md5";
const Upload_Image = (props) => {
  const initialState = {
    fileList: [],
    upload: null,
    isLoading: false,
    defaultFileList: [],
    isLoad: false,
  };
  const [state, setState] = useState(initialState);

  const handleDelete = async (fileSelect) => {
    let newStateFile = [];
    state.fileList.forEach((file, index) => {
      if (file.name == fileSelect.name) {
        file.status = "uploading";
      }
      newStateFile.push(file);
    });
    setState((state) => ({ ...state, fileList: newStateFile }));
    setState({ ...state, isLoading: true });

    // fetch(
    //   `${process.env.REACT_APP_API_URL}/media/delete?name=${fileSelect.response.data}`,
    //   {
    //     method: "POST",
    //     headers: {
    //       authorization: "Bearer " + localStorage.getItem("tcstore_token"),
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(async (response) => {
    //     const { data, status, msg } = response;
    //     status ? message.success(msg) : message.warning(msg);
    //   })
    //   .catch((err) => {
    //     message.warning("Xóa file không thành công, file không tồn tại");
    //   });
  };
  console.log(props, ' propssss');
  useEffect(() => {
    try {
      let newFileList = [];
      if (props.initialImg.length) {
        props.initialImg.map((img) => {

          let newItem = {
            originName: img[0].filename,
            name: img[0].filename,
            status: "done",
            url: img[0].url,
            response: {
              data: img,
            },
          };
          newFileList.push(newItem);
        });
        setTimeout(() => {
          setState({ ...state, defaultFileList: newFileList, isLoad: true });
        }, 1000);
      } else {
        setState({ ...state, isLoad: true });
      }
    } catch (error) {
      alert(error.message);
    }
  }, []);



  useEffect(() => {
    props.onChange({ key: props.item, data: state.defaultFileList });
    setState({ ...state, isLoad: true })
  }, [state.defaultFileList]);

  const initialStateUpload = {
    defaultFileList: state.defaultFileList,
    listType: props.max === 1 ? "picture-card" : "picture",
    className:
      props.max === 1
        ? "avatar-uploader"
        : isMobile
          ? "upload-list-inline_mobile"
          : "upload-list-inline",
    showUploadList: props.max === 1 ? false : true,
    maxCount: props.max,
    multiple: props.max === 1 ? false : true,
    accept: ".png,.jpeg,.jpg,.gif",
    name: "files",
    beforeUpload: async (file, fileList) => {
      const isPNG = file.type.toLowerCase() === "image/png";
      const isJPG = file.type.toLowerCase() === "image/jpg";
      const isJPEG = file.type.toLowerCase() === "image/jpeg";
      const isGIF = file.type.toLowerCase() === "image/gif";
      if (!isPNG && !isJPEG && !isJPG && !isGIF) {
        message.warning(
          `Vui lòng chọn file có đuôi png hoặc jpeg, jpg, gif - ${file.name}`
        );
        return false;
      } else if (state.fileList.length >= props.max) {
        message.warning(`Chỉ chọn tối đa ${props.max} file ảnh`);
        return false;
      } else {
        return true;
      }
    },
    onRemove(file) {
      handleDelete(file);
    },
    action: `${process.env.REACT_APP_API_URL}/media/upload-s3`,
    headers: {
      authorization: "Bearer " + localStorage.getItem("tcstore_token"),
      folder: props.folder ? props.folder : null,
    },
    onChange(info) {

      let { file, fileList } = info;
      if (file.status === 'uploading') {
        setState({ ...state, isLoading: true })
      }
      if (file.status === 'done') {
        fileList = fileList.map(file => ({ ...file, url: file.response.data[0].url }))
        setState((state) => ({ ...state, defaultFileList: fileList }));

      }
      if (file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      console.log(state.defaultFileList, 'hihhi')

    },
  };
  const uploadButton = (
    <div>
      {state.upload && state.upload.status === "loading" ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined />
      )}
      <div
        style={{
          marginTop: 2,
        }}
      >
        {props.text ? props.text : "Ảnh Bìa"}
      </div>
    </div>
  );

  return (
    <>
      {state.isLoad ? (
        <Upload
          style={{ height: 10 }}
          {...initialStateUpload}
          disabled={
            props.type == "item" &&
              (props.folder == "" || typeof props.folder == "undefined")
              ? true
              : props.product_type == 1
                ? true
                : false
          }
        >
          {props.max == 1 ? (
            state.defaultFileList.length ||
              (props.initialImg && props.initialImg.length > 0) ? (
              <Avatar
                src={
                  state.defaultFileList[0]?.url
                    ? state.defaultFileList[0].url
                    : `${process.env.REACT_APP_IMAGE_ENDPOINT}${props.folder ? `${md5(props.folder)}/` : ""
                    }${props.initialImg[0]}`
                }
                size="large"
                style={{
                  width: "60px",
                  height: "60px",
                }}
              />
            ) : (
              uploadButton
            )
          ) : (
            <Button
              disabled={
                props.type == "item" &&
                  (props.folder == "" || typeof props.folder == "undefined")
                  ? true
                  : props.product_type == 1
                    ? true
                    : false
              }
              type="dashed"
              style={{ marginRight: "-10px", width: "100%" }}
              ref={props.ref}
              danger
              icon={<UploadOutlined />}
            >
              Chọn ảnh
            </Button>
          )}
          >
          {/* {props.max == 1 ? (
            state.defaultFileList.length ||
            (props.initialImg && props.initialImg.length > 0) ? (
              <Avatar
                src={
                  state.defaultFileList[0].url
                    ? state.defaultFileList[0].url
                    : `${process.env.REACT_APP_API_URL}/media/${
                        props.folder ? `${md5(props.folder)}/` : ""
                      }${props.initialImg[0]}`
                }
                size="large"
                style={{
                  width: "60px",
                  height: "60px",
                }}
              />
            ) : (
              uploadButton
            )
          ) : (
            <Button
              disabled={
                props.type == "item" &&
                (props.folder == "" || typeof props.folder == "undefined")
                  ? true
                  : props.product_type == 1
                  ? true
                  : false
              }
              type="dashed"
              style={{ marginRight: "-10px", width: "100%" }}
              ref={props.ref}
              danger
              icon={<UploadOutlined />}
            >
              Chọn ảnh
            </Button>
          )} */}
        </Upload>
      ) : (
        <Skeleton />
      )}
    </>
  );
};
export default Upload_Image;
