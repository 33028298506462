import { API_PATH } from '../../constants/api.constants';
import { httpClient as request } from '../../utils/Api';

//
// @description
//
export const createNotification = (payload) => {
  return request.post(API_PATH.NOTIFICATION_CREATE, payload);
};

//
// @id
// @description
//
export const updateNotification = (payload) => {
  return request.post(API_PATH.NOTIFICATION_UPDATE, payload);
};
//
// @page
// @limit
// @q
//
export const getNotifications = (payload) => {
  return request.post(API_PATH.NOTIFICATION_SEARCH, payload);
};
//
// @id
//
export const deleteNotification = (payload) => {
  return request.post(API_PATH.NOTIFICATION_DELETE, payload);
};
//
// @description
//
export const createGuide = (payload) => {
  return request.post(API_PATH.GUIDE_CREATE, payload);
};

//
// @id
// @description
//
export const updateGuide = (payload) => {
  return request.post(API_PATH.GUIDE_UPDATE, payload);
};
//
// @page
// @limit
// @q
//
export const getGuides = (payload) => {
  return request.post(API_PATH.GUIDE_SEARCH, payload);
};
//
// @id
//
export const deleteGuide = (payload) => {
  return request.post(API_PATH.GUIDE_DELETE, payload);
};
//
// @id
//
export const getDetailGuide = (payload) => {
  return request.post(API_PATH.GUIDE_DETAIL, payload);
};
