import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";
// router.post("/boost/buy_boost", auth, boosts.buy_boost);
// router.post("/boost/update_boost", auth, boosts.update_boost);
// router.post("/boost/get_boost", auth, boosts.get_boost);
// router.post("/boost/apply_boost", auth, boosts.apply_boost);

export function* buy_boost(action) {
  try {
    const { data, status } = yield call(services.buy_boost, action.payload);
    yield put({
      type: types.BUY_BOOST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.BUY_BOOST_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.BUY_BOOST_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_boost(action) {
  try {
    const { data, status } = yield call(services.update_boost, action.payload);
    yield put({
      type: types.UPDATE_BOOST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_BOOST_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_BOOST_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_boost(action) {
  try {
    const { data, status } = yield call(services.get_boost, action.payload);
    yield put({
      type: types.GET_BOOST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_BOOST_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_BOOST_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* apply_boost(action) {
  try {
    const { data, status } = yield call(services.apply_boost, action.payload);
    yield put({
      type: types.APPLY_BOOST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.APPLY_BOOST_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.APPLY_BOOST_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_boost_price(action) {
  try {
    const { data, status } = yield call(services.get_boost_price, action.payload);
    yield put({
      type: types.GET_BOOST_PRICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_BOOST_PRICE_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_BOOST_PRICE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* boostSaga() {
  yield takeLatest(types.BUY_BOOST, buy_boost);
  yield takeLatest(types.UPDATE_BOOST, update_boost);
  yield takeLatest(types.GET_BOOST, get_boost);
  yield takeLatest(types.APPLY_BOOST, apply_boost);
  yield takeLatest(types.GET_BOOST_PRICE, get_boost_price);

}
