import { message } from 'antd';
import { uploadFileService } from './service';

export const beforeUpload = (file, fileList, max) => {
  const isPNG = file.type.toLowerCase() === 'image/png';
  const isJPG = file.type.toLowerCase() === 'image/jpg';
  const isJPEG = file.type.toLowerCase() === 'image/jpeg';
  const isGIF = file.type.toLowerCase() === 'image/gif';
  if (!isPNG && !isJPEG && !isJPG && !isGIF) {
    message.warning(
      `Vui lòng chọn file có đuôi png hoặc jpeg, jpg, gif - ${file.name}`
    );
    return false;
  } else if (fileList.length > max) {
    message.warning(`Chỉ chọn tối đa ${max} file ảnh`);
    return false;
  } else {
    return true;
  }
};

export const getUrls = async (fileList) => {

  const originFiles = fileList?.filter(f => f?.originFileObj);
  const promise = originFiles?.map((f) => {
    const formData = new FormData();
    formData.append('files', f?.originFileObj);

    return uploadFileService(formData).then((r) => r?.data?.data[0]?.url);
  });

  const result = await Promise.all(promise);
  return result;
};

export const convertUrlToFileList = (files) => {
  if (files?.length > 0) {
    return files.map((f, key) => ({ uid: f + key + f.key, url: f ,status: 'done'}));
  }

  return [];
};
