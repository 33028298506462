/* eslint-disable jsx-a11y/alt-text */
import { Button, Checkbox, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  KeyOutlined,
  LoginOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { forgot_password } from '../../modules/user/services';

export const REG_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const REG_PHONE = /^\+?\(?\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/;

export default function ForgotPassword() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (values) => {
    setIsLoading(true);
    delete values.re_password;
    try {
      const res = await forgot_password(values);
      if (res?.data?.status) {
        message.success('Thay đổi password thành công');
        history.push('/signin');
      } else {
        message.error({
          message: res?.data?.msg ?? 'Thay đổi password thất bại',
        });
      }
    } catch (error) {
      message.error(error?.response?.data?.msg ?? 'Thay đổi password thất bại');
    }

    setIsLoading(false);
  };
  return (
    <Form onFinish={onFinish}>
      <div className='center' style={{ width: isMobile ? '85%' : null }}>
        <div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <img
              width='200'
              src='https://images.viblo.asia/a87852d0-d60c-4a7c-ae42-0bfb6679ecb3.gif'
            />
            <h3>Quên mật khẩu</h3>
          </div>
          <Form.Item
            name={'email'}
            rules={[
              { required: true, message: 'Nhập địa chỉ email' },
              {
                pattern: REG_EMAIL,
                message: 'Chưa đúng định dạng email',
              },
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>
          <Form.Item
            name={'phone'}
            rules={[
              { required: true, message: 'Nhập số điện thoại' },
              {
                pattern: REG_PHONE,
                message: 'Chưa đúng định dạng số điện thoại',
              },
            ]}
          >
            <Input placeholder='Số điện thoại' />
          </Form.Item>
          <Form.Item
            name={'password'}
            rules={[{ required: true, message: 'Nhập mật khẩu mới' }]}
          >
            <Input.Password type='password' placeholder='Mật khẩu' />
          </Form.Item>
          <Form.Item
            name={'re_password'}
            rules={[
              { required: true, message: 'Nhập lại mật khẩu' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Không trùng với mật khẩu mới')
                  );
                },
              }),
            ]}
          >
            <Input.Password
              type='re_password'
              placeholder='Nhập lại mật khẩu'
            />
          </Form.Item>
          <br />
        </div>
        <div style={{ width: '100%', paddingBottom: 10 }}>
          <Form.Item noStyle>
            <Button
              icon={<LoginOutlined />}
              style={{ width: '49%', margin: 0, marginRight: '2%' }}
              type='primary'
              // onClick={() => loginUser()}
              loading={isLoading}
              className='color1'
              htmlType='submit'
            >
              Gửi
            </Button>
          </Form.Item>

          <Button
            icon={<UserAddOutlined />}
            style={{ width: '49%', margin: 0 }}
            type='primary'
            onClick={() => history.push('/signin')}
            className='color2'
          >
            Về đăng nhập
          </Button>
        </div>
      </div>
    </Form>
  );
}
