import * as Types from "./constants";

const initState = {
  accountList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  addAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  changeAccountInfo: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  cancelRequestChangeAccountInfo: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  buyAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  historyList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  updateHistory: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteHistory: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  confirmChangeProfileAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  accountPrice: {
    isLoading: false,
    status: false,
    msg: null,
    data: 0
  },
  accountInfo: {
    isLoading: false,
    status: false,
    msg: null,
    data: 0
  },
};
export default function accountReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.ADD_ACCOUNT:
      return {
        ...state,
        addAccount: {
          ...state.addAccount,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.ADD_ACCOUNT_SUCCESS:
      if (action.payload.status == true)
        state.accountList.data = [action.payload.data].concat(state.accountList.data)

      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: state.accountList.data
        },
        addAccount: {
          ...state.addAccount,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.ADD_ACCOUNT_ERROR:
      return {
        ...state,
        addAccount: {
          ...state.addAccount,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////f
    case Types.ADD_ACCOUNT_RANDOM:
      return {
        ...state,
        addAccount: {
          ...state.addAccount,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.ADD_ACCOUNT_RANDOM_SUCCESS:
      if (action.payload.status == true) {
        state.accountList.data = action.payload.data.concat(state.accountList.data)
      }

      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: state.accountList.data
        },
        addAccount: {
          ...state.addAccount,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.ADD_ACCOUNT_RANDOM_ERROR:
      return {
        ...state,
        addAccount: {
          ...state.addAccount,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    //////////
    case Types.CHANGE_ACCOUNT_INFO:
      return {
        ...state,
        changeAccountInfo: {
          ...state.changeAccountInfo,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.CHANGE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        changeAccountInfo: {
          ...state.changeAccountInfo,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.CHANGE_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        changeAccountInfo: {
          ...state.changeAccountInfo,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.CANCEL_REQUEST_CHANGE_ACCOUNT_INFO:
      return {
        ...state,
        cancelRequestChangeAccountInfo: {
          ...state.cancelRequestChangeAccountInfo,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        cancelRequestChangeAccountInfo: {
          ...state.cancelRequestChangeAccountInfo,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        cancelRequestChangeAccountInfo: {
          ...state.cancelRequestChangeAccountInfo,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.UPDATE_ACCOUNT:
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_ACCOUNT_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.accountList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.accountList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: [...state.accountList.data]
        },
        updateAccount: {
          ...state.updateAccount,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.GET_ACCOUNT_BY_ADMIN:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ACCOUNT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ACCOUNT_BY_ADMIN_ERROR:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.GET_ACCOUNT_BY_CATEGORY:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ACCOUNT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ACCOUNT_BY_CATEGORY_ERROR:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.GET_ACCOUNT_OWNER_ERROR:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ACCOUNT_OWNER_SUCCESS:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ACCOUNT_OWNER_ERROR:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.BUY_ACCOUNT:
      return {
        ...state,
        buyAccount: {
          ...state.buyAccount,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.BUY_ACCOUNT_SUCCESS:
      //console.log(action.payload)
      return {
        ...state,
        buyAccount: {
          ...state.buyAccount,
          ...action.payload,
          isLoading: false,
        }

      };
    case Types.BUY_ACCOUNT_ERROR:
      return {
        ...state,
        buyAccount: {
          ...state.buyAccount,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
    /////////
    case Types.DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_ACCOUNT_SUCCESS:
      if (action.payload.status) {
        state.accountList.data = state.accountList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: state.accountList.data
        },
        deleteAccount: {
          ...state.deleteAccount,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
    ///////
    case Types.GET_HISTORYS:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_HISTORYS_SUCCESS:
      //console.log(action)
      return {
        ...state,
        historyList: {
          ...state.historyList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_HISTORYS_ERROR:
      return {
        ...state,
        historyList: {
          ...state.historyList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.UPDATE_HISTORYS:
      return {
        ...state,
        updateHistory: {
          ...state.updateHistory,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_HISTORYS_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.historyList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.historyList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        historyList: {
          ...state.historyList,
          data: [...state.historyList.data]
        },
        updateHistory: {
          ...state.updateHistory,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_HISTORYS_ERROR:
      return {
        ...state,
        updateHistory: {
          ...state.updateHistory,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    ///////
    case Types.DELETE_HISTORYS:
      return {
        ...state,
        deleteHistory: {
          ...state.deleteHistory,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.CONFIRM_CHANGE_PROFILE:
      return {
        ...state,
        updateHistory: {
          ...state.updateHistory,
          status: true
        },
        confirmChangeProfileAccount: {
          ...state.confirmChangeProfileAccount,
          status: false,
          isLoading: true,
          msg: null,
        }
      }
    case Types.DELETE_HISTORYS_SUCCESS:
      if (action.payload.status) {
        state.historyList.data = state.historyList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        historyList: {
          ...state.historyList,
          data: state.historyList.data
        },
        deleteHistory: {
          ...state.deleteHistory,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_HISTORYS_ERROR:
      return {
        ...state,
        deleteHistory: {
          ...state.deleteHistory,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
    ///////
    case Types.GET_ACCOUNT_PRICE:
      return {
        ...state,
        accountPrice: {
          ...state.accountPrice,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ACCOUNT_PRICE_SUCCESS:

      return {
        ...state,
        accountPrice: {
          ...state.accountPrice,
          data: action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ACCOUNT_PRICE_ERROR:
      return {
        ...state,
        accountPrice: {
          ...state.accountPrice,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    ///////
    case Types.GET_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ACCOUNT_INFO_SUCCESS:

      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          data: action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    /////////
    case Types.RESET_ACCOUNT:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
