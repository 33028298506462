import React, { useState, useEffect } from "react";
import { Row, Col, Button, Space, Select, Badge, Input, Modal } from "antd";
import { LogoutOutlined, SendOutlined, TagOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input
const RandomCreate = (props) => {
   let state = props.state
   let isMobile = props.isMobile
   let create = props.create
   let setState = props.setState
   let random = state.random
   const [categoryList, setCategoryList] = useState([])
   useEffect(() => {
      let categoryList = props.categoryList.data.filter(category => category.product_type == 1)
      setCategoryList(categoryList)
   }, [props.categoryList])

   return (
      <Modal title={"Thêm Account Random"}
         visible={state.isVisibleModalRandom}
         style={{ top: 20 }}
         destroyOnClose={true}
         onCancel={() => setState({ ...state, isVisibleModalRandom: false })}
         width={isMobile ? "90%" : "40%"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={create.isLoading}
               onClick={() => {
                  props.handleCreate()
               }} type="primary" icon={<SendOutlined />}>{"Thêm Account"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => setState({ ...state, isVisibleModalRandom: false })} type="primary" className='color2'>Thoát</Button>
         </Space>}
      >

         <Row >
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
               <Select
                  placeholder="Chọn danh mục"

                  defaultValue={random.category?._id}
                  value={random.category?._id}
                  style={{ width: "100%", }}
                  className={!isMobile ? "pdR" : "pdB"}
                  onChange={(value, options) => {
                     setState({ ...state, random: { ...random, category: { ...options } } })
                  }}
               >
                  {categoryList && categoryList.length > 0 ? categoryList.map(category =>
                     <Option value={category._id}  {...category}>{category.name}</Option>
                  ) : null}
               </Select>
               <Input size="middle" placeholder="Giá tiền" prefix={<TagOutlined />}
                  className={!isMobile ? "pdR" : "pdB"}
                  value={random?.category?.price}
                  addonAfter="Giá tiền"
                  disabled
               />
            </Col>
            {/* <Col xl={4} lg={4} md={4} sm={24} xs={24} className={!isMobile ? "pdR" : "pdB"}>
               <Upload_Image item={"img"} max={1} initialImg={random.img ? [random.img] : []} onChange={(result) => {

                  let img = random.img
                  if (result.data.length) {
                     result.data.filter(img => img.status == "done").map(item => {
                        img = item.response.data
                     })
                  }
                  else img = random.originImg
                  setState({ ...state, random: { ...random, img: img } })
               }} />
            </Col> */}
         </Row>
         <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className={!isMobile ? "pdR" : "pdB"}>
               <TextArea rows={10} size="middle"
                  placeholder="Nhập danh sách account. Định dạng username|password|email"
                  prefix={<TagOutlined />}
                  onChange={(e) => setState({ ...state, random: { ...random, account_list: e.target.value } })}
                  value={random.account_list}
               />
            </Col>
         </Row>

      </Modal>

   )
}
export default RandomCreate