import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";


export function* create_deposit(action) {
  try {
    const { data, status } = yield call(services.create_deposit, action.payload);
    yield put({
      type: types.CREATE_DEPOSIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.CREATE_DEPOSIT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.CREATE_DEPOSIT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_deposit(action) {
  try {

    const { data, status } = yield call(services.update_deposit, action.payload);
    yield put({
      type: types.UPDATE_DEPOSIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_DEPOSIT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_DEPOSIT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_deposit(action) {
  try {
    const { data, status } = yield call(services.delete_deposit, action.payload);
    yield put({
      type: types.DELETE_DEPOSIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_DEPOSIT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_DEPOSIT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_deposits(action) {
  try {
    const { data, status } = yield call(services.get_deposits, action.payload);
    yield put({
      type: types.GET_DEPOSIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_DEPOSIT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_DEPOSIT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}

export default function* depositSaga() {
  yield takeLatest(types.CREATE_DEPOSIT, create_deposit);
  yield takeLatest(types.UPDATE_DEPOSIT, update_deposit);
  yield takeLatest(types.DELETE_DEPOSIT, delete_deposit);
  yield takeLatest(types.GET_DEPOSIT, get_deposits);
}

