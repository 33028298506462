import { CrownOutlined } from "@ant-design/icons";
import { Card, Col, Row, Spin, Tag, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import Header_Line from "../../components/Header_Line";
import { get_accounts_provide } from "../../modules/account/services";
import { curencyFormat } from "../../utils/help";

const ProvidedDetail = () => {
  const [select, setSelect] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [id]);

  const getDetail = (id) => {
    setLoading(true);
    get_accounts_provide({ id, limit: 1, page: 0, type: 0 })
      .then((res) => {
        if (res?.data?.status && res?.data?.data && res?.status === 200) {
          setSelect(res.data?.data?.data[0]);
        }
      })
      .catch((err) => message.error("Lấy chi tiết tài khoản thất bại"))
      .finally(() => setLoading(false));
  };
  return (
    <Spin
      spinning={loading}
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      {select?._id ? (
        <div>
          <Card style={{ marginBottom: 10 }} bodyStyle={{ paddingBottom: 10 }}>
            <Row>
              <Col
                xl={6}
                lg={6}
                md={24}
                sm={24}
                xs={24}
                style={{ textAlign: "right", alignSelf: "center" }}
              >
                <img
                  width={"100%"}
                  height={180}
                  src={
                    select?.category?.product_type == 0
                      ? select.img
                      : `${process.env.REACT_APP_IMAGE_ENDPOINT}${select.category.img}`
                  }
                />
              </Col>
              <Col
                xl={18}
                lg={18}
                md={24}
                sm={24}
                xs={24}
                style={{
                  padding: !isMobile ? "0px 10px 0px 30px" : "0px 0px 10px 0px",
                }}
              >
                <Row>
                  <Col span={16}>
                    <b>#ID: {select._id}</b>
                    <br />
                    <span>Mô tả: {select.description}</span>
                    <br />
                    {select?.category?.discount !== 0 &&
                    select?.category?.discount !== null ? (
                      <>
                        <span
                          style={{
                            textDecorationLine: "line-through",
                            color: "rgb(255 31 31 / 80%)",
                          }}
                        >
                          Giá gốc: {curencyFormat(select.price)}
                        </span>
                        <br></br>
                        <span style={{}}>
                          Giảm ({select?.category?.discount}%):{" "}
                          {curencyFormat(
                            select.price -
                              (select.price / 100) * select?.category?.discount
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <h3 style={{}}>Giá: {curencyFormat(select.price)}</h3>
                      </>
                    )}
                  </Col>
                </Row>

                <Row style={{ marginTop: isMobile ? 0 : 28 }}>
                  <Col
                    xl={8}
                    lg={8}
                    md={24}
                    sm={24}
                    className={!isMobile ? "pdR" : "wi-100"}
                  >
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Danh Mục:{" "}
                      {select?.category?.name
                        ? select?.category?.name
                        : "Không Có"}
                    </Tag>
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Tướng: {select.champ ? select.champ : "Không Có"}
                    </Tag>
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Mùa: {select.season ? select.season : "Không Có"}
                    </Tag>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} style={{ width: "100%" }}>
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Skin: {select.skin ? select.skin : "Không Có"}
                    </Tag>
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Level: {select.level ? select.level : "Không Có"}
                    </Tag>
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Rank HT: {select.rank_ht ? select.rank_ht : "Không Có"}
                    </Tag>
                  </Col>
                  <Col
                    xl={8}
                    lg={8}
                    md={24}
                    sm={24}
                    className={!isMobile ? "pdL" : "wi-100"}
                  >
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Khung: {select.khung ? select.khung : "Không Có"}
                    </Tag>
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Rank: {select.rank ? select.rank : "Không Có"}
                    </Tag>
                    <Tag
                      style={{
                        width: "100%",
                        marginBottom: 6,
                        height: 30,
                        fontSize: 15,
                        paddingTop: 3,
                      }}
                      icon={<CrownOutlined />}
                      color=""
                    >
                      Khung HT: {select.khung_ht ? select.khung_ht : "Không Có"}
                    </Tag>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <Card>
            <div style={{ textAlign: "center" }}>
              <Header_Line
                text={"HÌNH ẢNH TÀI KHOẢN"}
                textColor="#656565"
                lineColor="#32c5d2"
              />
              {select.img_list.map((img) => {
                return (
                  <img
                    style={{ marginBottom: 20 }}
                    width={isMobile ? "100%" : "80%"}
                    src={img}
                  />
                );
              })}
            </div>
          </Card>
        </div>
      ) : null}
    </Spin>
  );
};

export default ProvidedDetail;

const ItemInfo = ({ title, text }) => {
  return (
    <Typography>
      {title}: <span>{text}</span>
    </Typography>
  );
};

const ItemButton = ({ title, text }) => {
  return (
    <Row>
      <Col span={8}>
        <CrownOutlined />
        <Typography>
          {title}:<span>{text}</span>
        </Typography>
      </Col>
    </Row>
  );
};
