import { CheckCircleOutlined, CopyOutlined, EyeInvisibleOutlined, EyeOutlined, EyeTwoTone, KeyOutlined, UserOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Input, message, Pagination, Select, Space, Table, Tabs, Tag, Tooltip } from "antd";
import moment from "moment";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { register_ctv, reset_user } from "../../../modules/user/actions";
import { curencyFormat } from "../../../utils/help";
import BoostView from "./BoostView";

import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { get_boost, reset_boost, update_boost } from "../../../modules/boost/actions";
import { get_rank_name } from "../../../utils/help";

const { Option } = Select;
const { TextArea } = Input;


const Categorys = (props) => {
   const dispatch = useDispatch();
   const _user = useSelector(({ user }) => user.me.user);
   const _boostList = useSelector(({ boost }) => boost.boostList);
   const _updateBoost = useSelector(({ boost }) => boost.updateBoost);
   const _addBoost = useSelector(({ boost }) => boost.addBoost);
   const _deleteBoost = useSelector(({ boost }) => boost.deleteBoost);
   const _settingList = useSelector(({ setting }) => setting.settingList);
   const _registerCtv = useSelector(({ user }) => user.registerCtv);
   const history = useHistory()
   let initialState = {
      page: 0,
      select: null,
      random: {
         category: null,
         img: null,
         type: 0
      },
      log: null,
      boostLog: null,

      search: null,
      type: null,
      owner: null,
      receiver: null,
      hasReceiver: false,
      hasOwner: false,
      hasType: false,
      hasCategory: false,
      hasSearch: false,
      isVisibleConfirmDelete: false,
      isVisibleModalRandom: false,
      isVisibleModal: false,
      keyPanel: "1",
      boost_profit: 80,
      isVisibleAlert: false

   }
   const [state, setState] = useState(initialState)
   const [search] = useDebounce(state.search, 1000);
   const handleCTVRegister = () => {
      if (_user.balance < state.ctv_fee)
          return message.warning("Tài khoản của bạn không đủ tiền để trả phí đăng kí. Vui lòng nạp thêm!")
      setState({ ...state, isVisibleAlert: false })
      dispatch(register_ctv({}))
  }
   const handleGetBoost = async () => {
      dispatch(get_boost({
         request_type: state.keyPanel == "1" ? 1 : 2,
         receiver: state.receiver,
         type: state.type,
         search: state.search,
         page: state.page
      }))
   }
   const changePage = (page) => {
      setState({ ...state, page: page })
      dispatch(get_boost({
         request_type: state.keyPanel == "1" ? 1 : 2,
         receiver: state.receiver,
         type: state.type,
         search: state.search,
         page: page - 1,
      }))
   }
   const handleUpdateLog = async () => {

      if (state.log !== null) {
         let payload = {
            ...state.boostLog,
            add_log: state.log
         }
         dispatch(update_boost(payload))
      }
   }

   useEffect(() => {
      if (state.type !== null || state.hasType) {
         setState({ ...state, hasType: true })
         handleGetBoost()
      }
   }, [state.type])

   useEffect(() => {
      if (state.search || state.hasSearch) {
         setState({ ...state, hasSearch: true })
         handleGetBoost()
      }
   }, [search])
   useEffect(() => {
      window.scrollTo(0, 0)

      return () => {
         dispatch(reset_boost({ remove: ["all"] }))
         dispatch(reset_user({ remove: ["registerCtv"] }))
      };
   }, [])
   useEffect(() => {
      if (_registerCtv.status) {
          message.success(_registerCtv.msg)
          history.go(0)
      }
      else if (_registerCtv.status == false && _registerCtv.msg !== null) {
          message.warning(_registerCtv.msg)
      }
  }, [_registerCtv])
   const handleShowModal = (record) => {
      if (record) {
         setState({
            ...state, select: {
               ...record,
            }, isVisibleModal: true
         })
      }

   }

   const handleUpdateBoost = () => {
      let payload = {
         ...state.select
      }
      dispatch(update_boost(payload))
   }
   useEffect(() => {
      if (_settingList.status) {
          let boost_profit = _settingList.data.length && _settingList.data.find(item => item.key == "boost_profit")
          let ctv_fee = _settingList.data.length && _settingList.data.find(item => item.key == "ctv_fee")
         
          setState({
              ...state,
              boost_profit: boost_profit.value, ctv_fee: ctv_fee.value
          })
      }
      else if (_settingList.status == false && _settingList.msg !== null) {
          message.warning("Không tìm thấy cấu hình server")
      }
  }, [_settingList])
   useEffect(() => {
      if (_updateBoost.status) {
         message.success(_updateBoost.msg)
         setState({ ...state, log: null, boostLog: null })
      }
      else if (!_updateBoost.status && _updateBoost.msg !== null) message.warning(_updateBoost.msg)
   }, [_updateBoost])
   useEffect(() => {
     if (!_boostList.status && _boostList.msg !== null) message.warning(_boostList.msg)
   }, [_boostList])
   const handleChangeView = (key)=>{
       setState({...state, keyPanel: key})
      
   }
   useEffect(()=>{
      if(state.keyPanel)
      handleGetBoost()
   },[state.keyPanel])
   let columns = [
      {
         title: 'Thông tin',
         key: 'id',
         dataIndex: 'id',
         width: 100,

         render: (text, record) => {
            return (<>
               <span>Mã: {text}</span><br />
               <span>{moment(record.createdAt).format("DD/MM/YYYY")}</span>
            </>)
         }
      },
      {
         title: 'Account',
         dataIndex: 'username',
         key: 'username',
         width: 150,
         ellipsis: true,
         render: (text, record) => {
            return <>
               <Space>
                  {/* <Avatar size="default" src={"https://cdn-icons-png.flaticon.com/512/2314/2314909.png"} /> */}
                  <div style={{ alignItems: "center" }}>

                     <Input.Group compact>
                        <Input style={{ width: "90%" }} size="small" placeholder="Password" prefix={<UserOutlined />}
                           value={record.username}

                        />

                        <Button type="default" size="small" icon={<CopyOutlined />} onClick={() => {
                           message.success("Copy thành công")
                           navigator.clipboard.writeText(`${record.username}|${record.password}`)
                        }} />

                     </Input.Group>

                     <Input.Password size="small" style={{ marginTop: "5px" }} placeholder="Password" prefix={<KeyOutlined />}
                        value={record.password}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                     />
                  </div>
               </Space>
            </>
         }
      },
      {
         title: "Rank",
         key: 'want_rank',
         dataIndex: 'want_rank',
         width: 150,
         render: (text, record) => <div>
            <span>
               {`${get_rank_name(record.current_rank)} -> ${get_rank_name(record.want_rank)}`}
            </span><br />
            <span>
               {record.rank_type == 0 ? "Đơn/Đôi" : "Flex/Linh hoạt"}
            </span>

         </div>
      },
      {
         
         title: state.keyPanel == "1"? "Giá tiền" : `Nhận được`,
         key: 'price',
         dataIndex: 'price',
         width: 150,
         align: "right",
         render: (text, record) => state.keyPanel == "1"? <>

            <span>Giá gốc: {!isNaN(record?.price_before) ? curencyFormat(record?.price_before) : record?.price_before}</span><br />
            <span>Thanh toán: {!isNaN(text) ? curencyFormat(text) : text}</span>
         </> :  <span>{!isNaN(text) ? curencyFormat(((text / 100) * state.boost_profit)) : text} ({state.boost_profit}%)</span>
      },
      {
         title: "Hoàn thành",
         key: 'finish_date',
         dataIndex: 'finish_date',
         width: 150,
         align: "right",
         render: (text, record) => text ? <>
         <span>Yêu cầu: {`${moment(text).diff(moment(record.createdAt), "day")} Ngày`}</span><br/>
         <span>Ngày nhận: { record.received_date ? `${moment(record.received_date).format("DD/MM/YYYY")}`: "Chưa nhận"}</span>
         </> : "Không rõ"
      },
      {
         title: state.keyPanel == "1" ? "Người nhận" : "Người thuê",
         key:  state.keyPanel == "1" ? 'receiver' : "owner",
         dataIndex:  state.keyPanel == "1" ? 'receiver' : "owner",
         width: 100,
         render: (text) => text ? <>
            <span>{text.username}</span><br/>
            <span>{text.phone}</span>
         </> : <Tag style={{ borderRadius: 10 }} color={"orange"}>
            Chưa nhận
         </Tag>
      },
      {
         title: "Thêm ghi chú",
         key: 'log',
         dataIndex: 'log',
         width: 150,
         render: (text, record) => <div style={{ display: "flex", flexDirection: "row" }}>
            <TextArea style={{ height: "60px" }}
               onClick={() => {
                  setState({ ...state, boostLog: record })
               }}
               onChange={(e) => {
                  setState({ ...state, log: e.target.value })
               }}
               rows={2} value={state.boostLog && state.boostLog._id == record._id ? state.log : ""} ></TextArea>
            <Button onClick={() => handleUpdateLog()} style={{ height: "60px", width: "35px", marginLeft: 5 }} className="green" icon={<CheckCircleOutlined />}></Button>
         </div>
      },
      {
         title: "Trạng thái",
         key: 'type',
         dataIndex: 'type',
         width: 80,
         align: "center",
         render: (text) => <Tag style={{ borderRadius: 10 }} color={text == 0 ? "orange" : text == 1 ? "blue" : text == 2 ? "red" : "green"}>{text == 0 ? "Chưa nhận" : text == 1 ? "Đã nhận" : text == 2 ? "Đã hủy" : "Hoàn thành"}</Tag>
      },
      {
         title: 'Menu',
         width: 60,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (

            <Space>
               <Tooltip placement="bottom" title={"Xem đơn hàng"}>
                  <Button
                     type="primary"
                     size="small"
                     icon={<EyeOutlined />}

                     onClick={() => {
                        handleShowModal(record)
                     }}
                  ></Button>
               </Tooltip>

            </Space >

         ),
      },
   ];
   let columns_mobile = [

      {
         title: 'Đơn hàng',
         dataIndex: 'username',
         key: 'username',
         ellipsis: true,
         render: (text, record) => {
            return <>
               <span>
                  User: {record.username}
               </span>
               <br />
               <span>
                  Pass: {record.password}
               </span>
            </>
         }
      },
      {
         title: 'Menu',
         width: 60,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (
            <Space>
               <Tooltip placement="bottom" title={"Xem đơn hàng"}>
                  <Button
                     type="primary"
                     size="small"
                     icon={<EyeOutlined />}

                     onClick={() => {
                        handleShowModal(record)
                     }}
                  ></Button>
               </Tooltip>

            </Space >
         ),
      },
   ];
   const optionTable = {
      bordered: true,
      loading: _boostList.isLoading,
      size: 'small',
      showHeader: true,
      columns:   state.keyPanel == "1" ? columns : columns.filter(col => col.title !== "Menu") ,
      dataSource: [..._boostList.data],
      pagination: { position: ['none', 'none'], defaultPageSize: _boostList.per_page },
      scroll: isMobile ? {y: "calc(100vh - 300px)", x: 1200 } : { y: "calc(100vh - 300px)", x: 1000 },
      expandable: {
         expandedRowRender: (record) => (
            <>
               {

                  record.log.map((log) => <p
                     style={{
                        margin: 0,
                     }}
                  >
                     {log}
                  </p>)}
            </>
         ),
         rowExpandable: (record) => record?.log?.length !== 0
      } 
   };


   return (
      <>
      <Tabs defaultActiveKey="1" onChange={handleChangeView} activeKey={state.keyPanel}>
         <Tabs.TabPane tab="Quản lý tài khoản" key="1">
            <Card
               bodyStyle={{ padding: 10 }}
               headStyle={{ padding: 10 }}
               size="small"
               title={"Quản lý tài khoản"}
               extra={
                  <div>
                     <Input type="search"
                        style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, }}
                        placeholder="Tìm tài khoản / mã đơn hàng"
                        onChange={(e) => setState({ ...state, search: e.target.value })}
                        value={state.search}
                     ></Input>
      
                     
                     <Select
                        defaultValue={state.type}
                        value={state.type}
                        style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, marginLeft: !isMobile ? 10 : 0 }}
                        onChange={(value) => {
                           setState({ ...state, type: value })
                        }}
                     >
                        <Option value={null}>Lọc theo trạng thái</Option>
                        <Option value={0}><Badge color={"orange"} size="20" style={{ marginRight: 10 }}></Badge>Chờ CTV nhận đơn</Option>
                        <Option value={1}><Badge color={"blue"} size="20" style={{ marginRight: 10 }}></Badge>CTV đã nhận đơn</Option>
                        <Option value={2}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>Đã hủy</Option>
                        <Option value={3}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Hoàn thành</Option>
                     </Select>



                  </div>

               }
               style={{ width: "100%" }}>

               <Table
                  {...optionTable}
                  rowKey={(record) => record._id}
               />
               <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                  <Pagination current={_boostList.current_page + 1} total={_boostList.total} pageSize={_boostList.per_page} onChange={changePage} style={{ paddingTop: 10 }} showSizeChanger={false} />
               </Space>

               {state.select ?
                  <BoostView
                     update={_updateBoost}
                     create={_addBoost}
                     isMobile={isMobile}
                     state={state}
                     setState={setState}
                     handleUpdate={handleUpdateBoost}
                  /> : null}


            </Card>

         </Tabs.TabPane>
         <Tabs.TabPane tab="Đơn cày thuê đã nhận" key="2">
         <Card
               bodyStyle={{ padding: 10 }}
               headStyle={{ padding: 10 }}
               size="small"
               title={"Đơn cày thuê đã nhận"}
               extra={
                  <div>
                     <Input type="search"
                        style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, }}
                        placeholder="Tìm tài khoản / mã đơn hàng"
                        onChange={(e) => setState({ ...state, search: e.target.value })}
                        value={state.search}
                     ></Input>
      
                     
                     <Select
                        defaultValue={state.type}
                        value={state.type}
                        style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, marginLeft: !isMobile ? 10 : 0 }}
                        onChange={(value) => {
                           setState({ ...state, type: value })
                        }}
                     >
                        <Option value={null}>Lọc theo trạng thái</Option>
                        {/* <Option value={0}><Badge color={"orange"} size="20" style={{ marginRight: 10 }}></Badge>Chờ CTV nhận đơn</Option> */}
                        <Option value={1}><Badge color={"blue"} size="20" style={{ marginRight: 10 }}></Badge>Đã nhận đơn</Option>
                        <Option value={2}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>Đã hủy</Option>
                        <Option value={3}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Hoàn thành</Option>
                     </Select>
                     <Button type="primary" style={{ width: isMobile ? "100%" : "150px", marginLeft: !isMobile ? 10 : 0, marginTop: 10 }}
                        onClick={() => {
                           if(_user == {} || typeof _user?.id  !== "undefined" || !_user?._id)
                              return message.warning("Vui lòng đăng nhập trước")
                            if (_user.role == 1) return message.warning("Bạn đã đăng ký CTV trước đây!")
                            setState({ ...state, isVisibleAlert: true })
                        }}
                        className="color1">
                        Đăng Ký CTV
                    </Button>


                  </div>

               }
               style={{ width: "100%" }}>
         <Table
                  {...optionTable}
                  rowKey={(record) => record._id}
               />
               <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                  <Pagination current={_boostList.current_page + 1} total={_boostList.total} pageSize={_boostList.per_page} onChange={changePage} style={{ paddingTop: 10 }} showSizeChanger={false} />
               </Space>
               </Card>
         </Tabs.TabPane>

      </Tabs>
        <SweetAlert show={state.isVisibleAlert}
        warning
        showCancel
        confirmBtnText={"Đăng Ký"}
        cancelBtnText={"Thui!"}
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title={`Thông báo!`}
        onConfirm={() => {
            handleCTVRegister()
        }}

        onCancel={() => setState({ ...state, isVisibleAlert: false })}
    >
        <div>
            <span>Bạn sẽ mất một khoản phí: {curencyFormat(state.ctv_fee)} để trở thành CTV của chúng tôi</span><br />
            <span>Nếu đồng ý, vui lòng chọn nút đăng ký dưới đây</span>
        </div>
    </SweetAlert>
   </>

   )
}
export default Categorys