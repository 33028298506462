import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";
// router.post("/discounts/add_discount", auth, discounts.add_discount);
// router.post("/discounts/update_discount", auth, discounts.update_discount);
// router.post("/discounts/delete_discount", auth, discounts.delete_discount);
// router.post("/discounts/buy_discount", auth, discounts.buy_discount);
// router.post("/discounts/get_discount", auth, discounts.get_discount);

export function* add_discount(action) {
  try {
    const { data, status } = yield call(services.add_discount, action.payload);
    yield put({
      type: types.ADD_DISCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.ADD_DISCOUNT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.ADD_DISCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_discount(action) {
  try {
    const { data, status } = yield call(services.update_discount, action.payload);
    yield put({
      type: types.UPDATE_DISCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_DISCOUNT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_DISCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_discounts(action) {
  try {
    const { data, status } = yield call(services.get_discounts, action.payload);
    yield put({
      type: types.GET_DISCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_DISCOUNT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_DISCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_discount(action) {
  try {
    const { data, status } = yield call(services.delete_discount, action.payload);
    yield put({
      type: types.DELETE_DISCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_DISCOUNT_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_DISCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* discountSaga() {
  yield takeLatest(types.ADD_DISCOUNT, add_discount);
  yield takeLatest(types.UPDATE_DISCOUNT, update_discount);
  yield takeLatest(types.GET_DISCOUNT, get_discounts);
  yield takeLatest(types.DELETE_DISCOUNT, delete_discount);
}
