import { useEffect, useState } from 'react';

export const useWindowResize = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the state with the new window width
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add the event listener for the window resize event
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { windowWidth };
};
