import { httpClient as request } from "../../utils/Api";
// router.post("/wheel/add_wheel", auth, wheels.add_wheel);
// router.post("/wheel/update_wheel", auth, wheels.update_wheel);
// router.post("/wheel/play", auth, wheels.play);
// router.post("/wheel/get_wheel", auth, wheels.get_wheel);

export const add_wheel = payload => {
  return request.post("/wheel/add_wheel", payload)
};

export const update_wheel = payload => {
  return request.post("/wheel/update_wheel", payload)
};

export const play_wheel = payload => {
  return request.post("/wheel/play", payload)
};

export const get_wheels = payload => {
  return payload.dashboard ? request.post("/wheel/get_wheels", payload) : request.post("/wheel/get_wheels_user", payload)
};

export const delete_wheel = payload => {
  return request.post("/wheel/delete_wheel", payload)
};
export const get_historys = payload => {
  return request.post("/wheel/get_historys", payload)
};