import {
  DollarCircleOutlined,
  FireOutlined,
  FundViewOutlined,
  HistoryOutlined,
  SkinOutlined,
  TrophyOutlined,
  UserOutlined,
  // IdcardOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import { Col, Menu, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
// import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Router from './Router';
import { useWindowResize } from '../../hook/useWindowResize';
import DrawerProfile from './DrawerProfile';

const App = (props) => {
  const { match, history, location } = props;
  const [current, setCurrent] = useState('/profile/me');
  const _me = useSelector(({ user }) => user.me);
  const _user = _me.user;
  const { windowWidth } = useWindowResize();
  const isMobile = windowWidth <= 1300 ? true : false;
  const isPhone = windowWidth <= 576 ? true : false;
  const items = [
    {
      label: (
        <Link to='/profile/me'>
          <span
            style={{
              color: isMobile && !isPhone ? 'white' : 'black',
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            Tài khoản
          </span>
        </Link>
      ),
      key: '/profile/me',
      icon: <UserOutlined />,
    },
    {
      label: (
        <Link to='/profile/deposit'>
          <span
            style={{
              color: isMobile && !isPhone ? 'white' : 'black',
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            Nạp tiền
          </span>
        </Link>
      ),
      key: '/profile/deposit',
      icon: <DollarCircleOutlined />,
    },
    {
      label: (
        <Link to='/profile/wildcore'>
          <span
            style={{
              color: isMobile && !isPhone ? 'white' : 'black',
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            Rút Wildcore
          </span>
        </Link>
      ),
      key: '/profile/wildcore',
      icon: <FundViewOutlined />,
    },
    // {
    //     label: <Link to="/profile/withdraw">
    //         <span style={{ color: isMobile? "white" : "black", fontSize: 14, marginLeft: 8 }}>Rút Tiền</span>
    //     </Link>,
    //     key: '/profile/withdraw',
    //     icon: <FundViewOutlined />,
    // },
    {
      title: 'Cày thuê',
      label: (
        <Link to='/profile/boostings'>
          <span
            style={{
              color: isMobile && !isPhone ? 'white' : 'black',
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            Cày thuê
          </span>
        </Link>
      ),
      key: '/profile/boostings',
      icon: <TrophyOutlined />,
    },

    {
      label: (
        <Link to='/profile/wheels'>
          <span
            style={{
              color: isMobile && !isPhone ? 'white' : 'black',
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            Lịch sử vòng quay
          </span>
        </Link>
      ),
      key: '/profile/wheels',
      icon: <HistoryOutlined />,
    },
    {
      label: (
        <Link to='/profile/accounts'>
          <span
            style={{
              color: isMobile && !isPhone ? 'white' : 'black',
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            Tài khoản đã mua/tặng
          </span>
        </Link>
      ),
      key: '/profile/accounts',
      icon: <FireOutlined />,
      style: { borderTop: '1px solid rgb(204 204 204 / 33%)' },
    },
    {
      label: (
        <Link to='/profile/items'>
          <span
            style={{
              color: isMobile && !isPhone ? 'white' : 'black',
              fontSize: 14,
              marginLeft: 8,
            }}
          >
            Skin / Vật phẩm đã mua
          </span>
        </Link>
      ),
      key: '/profile/items',
      icon: <SkinOutlined />,
    },
  ];
  useEffect(() => {
    if (location.pathname == '/profile/') setCurrent('/profile/me');
    else {
      setCurrent(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (_user && _user?.status == false && _user?.msg !== null) {
      message.warning('Vui lòng đăng nhập');
      history.push('/signin');
    }
  }, [_user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Row style={{ width: '100%' }} className='cpanel'>
      {/* <div className='menu-bottom'>
        {items.map((e, key) => {
          return (
            <div
              key={key + 'phone' + e.key}
              onClick={() => {
                window.scrollTo({ top: 0 });
                history.push(e.key);
              }}
            >
              {e.icon}
            </div>
          );
        })}
      </div> */}
      {isPhone && (
        <DrawerProfile
          node={
            <div className={'icon-drwaer'}>
              <p>Menu</p>
              <DoubleRightOutlined />
            </div>
          }
        >
          <Menu
            selectedKeys={[current]}
            mode='inline'
            className='menu_cpanel'
            items={items}
          />
        </DrawerProfile>
      )}

      {!isPhone && (
        <Col
          xl={4}
          xs={1}
          style={{
            background: 'white',
            borderRight: '1px solid rgb(241 241 241)',
          }}
        >
          <Menu
            selectedKeys={[current]}
            mode='inline'
            inlineCollapsed={isMobile}
            style={{
              minHeight: 'calc(100vh - 180px)',
              // height:"auto",
              // background:"white"
            }}
            className='menu_cpanel'
            items={items}
          />
        </Col>
      )}
      <Col xl={20} xs={isPhone ? 24 : 23}>
        <div
          style={{
            background: 'white',
            padding: 10,
            width: '100%',
            height: '100%',
          }}
        >
          <Router match={match} location={location} history={history} />
        </div>
      </Col>
    </Row>
  );
};

export default App;
