import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Button,
  Card,
  Select,
  Avatar,
  Pagination,
  Badge,
  Input,
  message,
  Tooltip,
  Space,
  Tag,
  Popconfirm,
} from 'antd';
import {
  CheckCircleOutlined,
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/vi';
import { curencyFormat } from '../../utils/help';
import { isMobile } from 'react-device-detect';
import { useDebounce } from 'use-debounce';
import { get_categorys } from '../../modules/category/actions';
import AccountView from './AccountView';
import RandomCreate from './RandomCreate';
import {
  add_account,
  add_account_random,
  delete_account,
  get_accounts_by_admin,
  reset_account,
  update_account,
} from '../../modules/account/actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { confirmAccount } from '../../modules/account/services';
import { ROUTE_PATH } from '../../constants/router.constants';

const { Option } = Select;
const { TextArea } = Input;

const AccountsProvide = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const _accountList = useSelector(({ account }) => account.accountList);
  const _updateAccount = useSelector(({ account }) => account.updateAccount);
  const _addAccount = useSelector(({ account }) => account.addAccount);
  const _deleteAccount = useSelector(({ account }) => account.deleteAccount);
  const _categoryList = useSelector(({ category }) => category.categoryList);
  const me = useSelector(({ user }) => user.me);
  const [open, setOpen] = useState(false);

  const profile = me.user;
  let initialState = {
    page: 0,
    category: null,
    search: null,
    type: null,
    select: null,
    random: {
      category: null,
      img: null,
      type: 0,
    },
    log: null,
    userLog: null,
    hasType: false,
    hasCategory: false,
    hasSearch: false,
    isVisibleConfirmDelete: false,
    isVisibleModalRandom: false,
    isVisibleModal: false,
  };
  const [state, setState] = useState(initialState);
  const [search] = useDebounce(state.search, 1000);

  const handleGetAccount = async () => {
    dispatch(
      get_accounts_by_admin({
        category: state.category,
        type: state.type,
        search: state.search,
        page: state.page,
        role: 'provider',
      })
    );
  };
  const handleGetCategory = async () => {
    dispatch(
      get_categorys({
        type: 0,
        page: 0,
        limit: 1000,
      })
    );
  };

  const handleUpdateLog = async () => {
    if (state.log !== null) {
      let payload = {
        ...state.userLog,
        log: state.log,
        role: 'provider',
      };
      dispatch(update_account(payload));
    }
  };
  useEffect(() => {
    if (state.type !== null || state.hasType) {
      setState({ ...state, hasType: true });
      handleGetAccount();
    }
  }, [state.type]);

  useEffect(() => {
    if (state.category !== null || state.hasCategory) {
      setState({ ...state, hasCategory: true });
      handleGetAccount();
    }
  }, [state.category]);
  useEffect(() => {
    if (state.search || state.hasSearch) {
      setState({ ...state, hasSearch: true });
      handleGetAccount();
    }
  }, [search]);
  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetAccount();
    handleGetCategory();
    return () => {
      dispatch(reset_account({ remove: ['all'] }));
    };
  }, []);
  const deleteConfirm = () => {
    setState({ ...state, isVisibleConfirmDelete: false });

    dispatch(
      delete_account({
        _id: state.select._id,
        role: 'provider',
      })
    );
  };

  const handleShowModal = (record) => {
    if (record) {
      setState({
        ...state,
        select: {
          ...record,
          originImg: record.img,
          originImgList: record.img_list,
        },
        isVisibleModal: true,
      });
    } else {
      setState({
        ...state,
        select: {
          _id: null,
          originImg: null,
          originImgList: null,
          is_hot: false,
          is_warranty: true,
          type: 0,
          img: null,
          img_list: [],
        },
        isVisibleModal: true,
      });
    }
  };
  const handleShowCreate = () => {
    setState({ ...state, isVisibleModalRandom: true });
  };
  const handleUpdateAccount = () => {
    let payload = {
      ...state.select,
      role: 'provider',
    };
    dispatch(update_account(payload));
  };
  const handleAddAccount = () => {
    let payload = {
      ...state.select,
      role: 'provider',
    };
    dispatch(add_account(payload));
  };
  const handleAddAccountRandom = () => {
    let payload = {
      ...state.random,
      account_list: state.random.account_list.split(/;?\r\n|;?\n|;?\r/),
      role: 'provider',
    };
    dispatch(add_account_random(payload));
  };
  useEffect(() => {
    if (_updateAccount.status) {
      message.success(_updateAccount.msg);
      setState({ ...state, log: null, userLog: null });
      handleGetAccount();
    } else if (!_updateAccount.status && _updateAccount.msg !== null)
      message.warning(_updateAccount.msg);
  }, [_updateAccount]);

  useEffect(() => {
    if (_deleteAccount.status) message.success(_deleteAccount.msg);
    else if (!_deleteAccount.status && _deleteAccount.msg !== null)
      message.warning(_deleteAccount.msg);
  }, [_deleteAccount]);

  useEffect(() => {
    if (_addAccount.status) {
      message.success(_addAccount.msg);
      setState({
        ...state,
        isVisibleModal: false,
      });
      handleGetAccount();
    } else if (!_addAccount.status && _addAccount.msg !== null)
      message.warning(_addAccount.msg);
  }, [_addAccount]);

  const [key, setKey] = useState('');
  const confirm = async (e) => {
    const result = await confirmAccount(e?._id);
    console.log(result, 'result');
    if (result?.status) {
      handleGetAccount();
      message.success('Phê duyệt tài khoản thành công');
    } else {
      message.error('Phê duyệt tài khoản thất bại');
    }
    setOpen(false);
    setKey('');
  };

  const cancel = (e) => {
    setOpen(false);
    setKey('');
  };

  const onOpenConfirm = (e) => {
    setOpen(true);
    setKey(e._id);
  };

  const handleViewDetail = (data) => {
    history.push({
      pathname: `${ROUTE_PATH.provideAccountDetail}${data?._id}`,
      state: data,
    });
  };
  let columns = [
    {
      title: 'Ngày tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 80,
      render: (text) => (
        <>
          <span>{moment(text).format('DD/MM/YYYY')}</span>
          <br />
          <span>{moment(text).format('HH:mm')}</span>
        </>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 50,
      align: 'center',
      render: (text) => text,
    },
    {
      title: 'Account',
      dataIndex: 'username',
      key: 'username',
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            <Space>
              <Avatar
                size='default'
                src={
                  record?.category?.product_type == 0
                    ? record.img
                    : record?.category?.img
                }
              />
              <div style={{ alignItems: 'center' }}>
                <span>
                  {record.is_warranty ? (
                    <Badge color={'green'} text={record.username} />
                  ) : (
                    <Badge color={'red'} text={record.username} />
                  )}
                </span>
                <br />
                <span>{record.email}</span>
              </div>
            </Space>
          </>
        );
      },
    },
    {
      title: 'Loại',
      key: 'category',
      dataIndex: 'category',
      width: 70,
      align: 'center',
      render: (text) => (
        <Tag
          style={{ borderRadius: 10 }}
          color={text?.product_type == 0 ? 'green' : 'orange'}
        >
          {text?.product_type == 0 ? 'VIP' : 'RANDOM'}
        </Tag>
      ),
    },

    {
      title: 'Giá tiền',
      key: 'price',
      dataIndex: 'price',
      width: 80,
      align: 'right',
      render: (text) => (!isNaN(text) ? curencyFormat(text) : text),
    },
    {
      title: 'Giảm giá',
      key: 'category',
      dataIndex: 'category',
      width: 70,
      align: 'right',
      render: (text) =>
        typeof text?.discount !== 'undefined' ? `${text?.discount}%` : '0%',
    },
    {
      title: 'Sở hữu',
      key: 'owner',
      dataIndex: 'owner',
      width: 100,
      render: (text) => (text ? text?.username : 'Đang bán'),
    },
    {
      title: 'Danh mục',
      key: 'category',
      dataIndex: 'category',
      width: 100,
      render: (text) =>
        text && typeof text.name !== 'undefined'
          ? text.name
          : 'Danh mục bị xóa',
    },
    {
      title: 'Thêm ghi chú',
      key: 'log',
      dataIndex: 'log',
      width: 150,
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextArea
            style={{ height: '60px' }}
            onClick={() => {
              setState({ ...state, userLog: record });
            }}
            onChange={(e) => {
              setState({ ...state, log: e.target.value });
            }}
            rows={2}
            value={
              state.userLog && state.userLog._id == record._id ? state.log : ''
            }
          ></TextArea>
          <Button
            onClick={() => handleUpdateLog()}
            style={{ height: '60px', width: '35px', marginLeft: 5 }}
            className='green'
            icon={<CheckCircleOutlined />}
          ></Button>
        </div>
      ),
    },
    {
      title: 'Menu',
      width: 100,
      align: 'center',
      render: (text, record) => (
        <Space style={{ flexWrap: 'wrap' }}>
          {profile.role === 0 && (
            <Popconfirm
              open={open && key === record._id}
              title='Phê duyệt tài khoản '
              description='Bạn có chắc chắn muốn phê duyệt tài khoản này?'
              onConfirm={() => confirm(record)}
              onCancel={cancel}
              okText='Đồng ý'
              cancelText='không'
              key={record?._id}
            >
              <Tooltip placement='bottom' title={'Phê duyệt'}>
                <Button
                  danger
                  size='small'
                  onClick={() => onOpenConfirm(record)}
                  icon={<CheckCircleOutlined />}
                ></Button>
              </Tooltip>
            </Popconfirm>
          )}
          {/* {profile.role === 3 && ( */}
          <Tooltip placement='bottom' title={'Chỉnh sửa account'}>
            <Button
              type='primary'
              size='small'
              icon={<EditOutlined />}
              onClick={() => {
                handleShowModal(record);
              }}
            />
          </Tooltip>
          {/* )} */}
          <Tooltip placement='bottom' title={'Xem account'}>
            <Button
              type='primary'
              size='small'
              icon={<EyeOutlined />}
              onClick={() => {
                handleViewDetail(record);
              }}
            ></Button>
          </Tooltip>
          <Tooltip placement='bottom' title={'Xóa account'}>
            <Button
              type='primary'
              size='small'
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                if (record.owner == null || !record?.owner?._id)
                  setState({
                    ...state,
                    select: record,
                    isVisibleConfirmDelete: true,
                  });
                else {
                  message.warning(
                    'Tài khoản đã được bán hoặc tặng, không thể xóa'
                  );
                }
              }}
            ></Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  let columns_mobile = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 50,
      align: 'center',
      render: (text) => text,
    },
    {
      title: 'Account',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            <span>{record.username}</span>
            <br />
            <span>{record.email}</span>
          </>
        );
      },
    },
    {
      title: 'Menu',
      width: 80,
      align: 'center',
      // fixed: 'right',
      render: (text, record) => (
        <Space>
          <Tooltip placement='bottom' title={'Xem account'}>
            <Button
              type='primary'
              size='small'
              icon={<EyeOutlined />}
              onClick={() => {
                handleShowModal(record);
              }}
            ></Button>
          </Tooltip>
          <Tooltip placement='bottom' title={'Xóa account'}>
            <Button
              type='primary'
              size='small'
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                if (record.owner == null || record.owner == {})
                  setState({
                    ...state,
                    select: record,
                    isVisibleConfirmDelete: true,
                  });
                else {
                  message.warning(
                    'Tài khoản đã được bán hoặc tặng, không thể xóa'
                  );
                }
              }}
            ></Button>
          </Tooltip>
        </Space>
      ),
    },
  ];
  const optionTable = {
    bordered: true,
    loading: _accountList.isLoading,
    size: 'small',
    showHeader: true,
    columns: isMobile ? columns_mobile : columns,
    dataSource: [..._accountList.data],
    pagination: {
      position: ['none', 'none'],
      defaultPageSize: _accountList.per_page,
    },
    scroll: isMobile ? {} : { y: 'calc(100vh - 300px)', x: 1000 },
    expandable: !isMobile
      ? {
          expandedRowRender: (record) => (
            <>
              {record.log.map((log) => (
                <p
                  style={{
                    margin: 0,
                  }}
                >
                  {log}
                </p>
              ))}
            </>
          ),
          rowExpandable: (record) => record?.log?.length !== 0 && !isMobile,
        }
      : null,
  };

  const changePage = (page) => {
    setState({ ...state, page: page });
    dispatch(
      get_accounts_by_admin({
        search: state.search,
        category: state.category,
        type: state.type,
        page: page - 1,
        role: profile?.role,
        role: 'provider',
      })
    );
  };

  useEffect(() => {
    if (profile && profile?.status == false && profile?.msg !== null) {
      message.warning('Vui lòng đăng nhập');
      history.push({
        pathname: '/signin',
        search: { prev: true },
      });
    }
    if (![0, 3].includes(profile?.role)) {
      message.warning('Không Có quyền thực thi hành động này');
      history.push('/');
    }
  }, [profile]);

  return (
    <Card
      bodyStyle={{ padding: 10 }}
      headStyle={{ padding: 10 }}
      size='small'
      title={'Quản lý tài khoản game'}
      extra={
        <div>
          <Input
            type='search'
            style={{ width: isMobile ? '100%' : '200px', marginBottom: 10 }}
            placeholder='Tìm id/username/email'
            onChange={(e) => setState({ ...state, search: e.target.value })}
            value={state.search}
          ></Input>
          <Select
            defaultValue={state.type}
            value={state.type}
            style={{
              width: isMobile ? '100%' : '200px',
              marginBottom: 10,
              marginLeft: !isMobile ? 10 : 0,
            }}
            onChange={(value) => {
              setState({ ...state, type: value });
            }}
          >
            <Option value={null}>Lọc theo trạng thái</Option>

            <Option value={0}>
              <Badge
                color={'green'}
                size='20'
                style={{ marginRight: 10 }}
              ></Badge>
              Đang bán
            </Option>
            <Option value={3}>
              <Badge
                color={'blue'}
                size='20'
                style={{ marginRight: 10 }}
              ></Badge>
              Lưu kho
            </Option>
            <Option value={1}>
              <Badge
                color={'red'}
                size='20'
                style={{ marginRight: 10 }}
              ></Badge>
              Đã bán
            </Option>
            <Option value={2}>
              <Badge
                color={'yellow'}
                size='20'
                style={{ marginRight: 10 }}
              ></Badge>
              Đã tặng
            </Option>
          </Select>
          <Select
            defaultValue={state.category}
            value={state.category}
            style={{
              width: isMobile ? '100%' : '200px',
              marginLeft: !isMobile ? 10 : 0,
            }}
            onChange={(value) => {
              setState({ ...state, category: value });
            }}
          >
            <Option key='xxxx' value={null}>
              Lọc theo danh mục
            </Option>
            {_categoryList.data.length
              ? _categoryList.data.map((category, index) => (
                  <Option key={index} value={category._id}>
                    {category.name}
                  </Option>
                ))
              : null}
          </Select>
          {profile?.role === 3 && (
            <Popconfirm
              placement='bottom'
              title='Chọn loại acc'
              onConfirm={() => handleShowModal(null)}
              onCancel={() => handleShowCreate()}
              okText='VIP'
              cancelText={''}
              showCancel={false}
            >
              <Button
                type='primary'
                style={{
                  width: isMobile ? '100%' : '150px',
                  marginLeft: !isMobile ? 10 : 0,
                  marginTop: 10,
                }}
                className='color1'
              >
                Đăng Acc
              </Button>
            </Popconfirm>
          )}
        </div>
      }
      style={{ width: '100%' }}
    >
      <Table {...optionTable} rowKey={(record) => record._id} />
      <Space
        direction='horizontal'
        style={{ width: '100%', justifyContent: 'center' }}
      >
        <Pagination
          current={_accountList.current_page + 1}
          total={_accountList.total}
          pageSize={_accountList.per_page}
          onChange={changePage}
          style={{ paddingTop: 10 }}
          showSizeChanger={false}
        />
      </Space>

      {state.select ? (
        <AccountView
          categoryList={_categoryList}
          update={_updateAccount}
          create={_addAccount}
          isMobile={isMobile}
          state={state}
          setState={setState}
          handleUpdate={handleUpdateAccount}
          handleCreate={handleAddAccount}
        />
      ) : null}
      <RandomCreate
        categoryList={_categoryList}
        create={_addAccount}
        isMobile={isMobile}
        state={state}
        setState={setState}
        handleCreate={handleAddAccountRandom}
      />
      <SweetAlert
        show={state.isVisibleConfirmDelete}
        warning
        showCancel
        confirmBtnText={'Oke. Xóa'}
        confirmBtnBsStyle='danger'
        cancelBtnBsStyle='default'
        title={`Bạn chắc chắn muốn xóa Account này?`}
        onConfirm={deleteConfirm}
        onCancel={() => setState({ ...state, isVisibleConfirmDelete: false })}
      ></SweetAlert>
    </Card>
  );
};
export default AccountsProvide;
