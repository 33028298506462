import { httpClient as request } from '../../utils/Api';
// router.post("/boost/buy_boost", auth, boosts.buy_boost);
// router.post("/boost/update_boost", auth, boosts.update_boost);
// router.post("/boost/get_boost", auth, boosts.get_boost);
// router.post("/boost/apply_boost", auth, boosts.apply_boost);
export const buy_boost = (payload) => {
  return request.post('/boost/buy_boost', payload);
};

export const update_boost = (payload) => {
  return request.post('/boost/update_boost', payload);
};

export const get_boost = (payload) => {
  return request.post('/boost/get_boost', payload);
};

export const apply_boost = (payload) => {
  return request.post('/boost/apply_boost', payload);
};

export const get_boost_price = (payload) => {
  return request.post('/boost/get_boost_price', payload);
};

export const update_friend_id = (payload) => {
  return request.post('/user/update_friend_id', payload);
};

export const get_friend_saved_id = (payload) => {
  return request.post('/user/find_friend_by_name', payload);
};
export const find_user_save_friends = (payload) => {
  return request.post('/user/find_user_save_friends', payload);
};
export const remove_user_id_friend = (payload) => {
  return request.post('/user/remove_user_id_friend', payload);
};
