import {
  AimOutlined,
  CrownOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
  LogoutOutlined,
  MailOutlined,
  NumberOutlined,
  SendOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Badge, Button, Col, Collapse, Input, Row, Select, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import "moment/locale/vi";
import React, { useEffect, useState } from "react";
import Upload_Image from "../../components/Upload_Image";
import {
  FRAME_RANK_LENGEND,
  RANK_LENGEND,
} from "../../constants/data.constants";
import { NumericInput, formatDataSumPrice } from "../../utils/help";
import UploadImage from "../../components/UploadImage";
import md5 from "md5";

const { Option } = Select;
const { Panel } = Collapse;
const CategoryView = (props) => {
  let state = props.state;
  let isMobile = props.isMobile;
  let update = props.update;
  let create = props.create;
  let setState = props.setState;
  let select = state.select;

  const [keyPanel, setKeyPanel] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    if (select._id) {
      setCategoryList(props.categoryList.data);
    } else {
      let categoryList = props.categoryList.data.filter(
        (category) => category.product_type == 0
      );

      setCategoryList(categoryList);
    }
    return () => {
      setCategoryList([]);
    };
  }, [select._id]);

  const handleSearchData = async (value) => {
    return fetch(`${process.env.REACT_APP_API_URL}/user/search_users`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + localStorage.getItem("tcstore_token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ search: value }),
    })
      .then((response) => response.json())
      .then((body) =>
        body.data.map((user) => ({
          label: `${user.username}`,
          value: user._id,
        }))
      );
  };
  const handleSumPrice = () => {
    const newDataSelect = formatDataSumPrice(select);
    setState({ ...state, select: newDataSelect });
  };
  return (
    <Modal
      title={select._id !== null ? `Chỉnh sửa` : "Thêm Account"}
      visible={state.isVisibleModal}
      style={{ top: 20 }}
      destroyOnClose={true}
      onCancel={() => setState({ ...state, isVisibleModal: false })}
      width={isMobile ? "90%" : "60%"}
      footer={
        <Space
          direction="horizontal"
          style={{
            width: "100%",
            justifyContent: isMobile ? "center" : "right",
          }}
          className="gx-w-100"
        >
          <Button
            className="color1"
            loading={select._id !== null ? update.isLoading : create.isLoading}
            onClick={() => {
              if (select._id !== null) {
                props.handleUpdate();
              } else {
                props.handleCreate();
              }
            }}
            type="primary"
            icon={<SendOutlined />}
          >
            {select._id !== null ? "Cập nhật" : "Thêm Account"}
          </Button>
          <Button
            icon={<LogoutOutlined />}
            onClick={() => setState({ ...state, isVisibleModal: false })}
            type="primary"
            className="color2"
          >
            Thoát
          </Button>
        </Space>
      }
    >
      <Collapse
        accordion
        activeKey={keyPanel}
        defaultActiveKey={keyPanel}
        onChange={(e) => {
          setKeyPanel(e);
        }}
      >
        <Panel header="Thông tin Account" key={1}>
          <Row>
            <Col xl={20} lg={20} md={20} sm={24} xs={24}>
              {select.id ? (
                <Input
                  size="middle"
                  placeholder="ID"
                  prefix={<NumberOutlined />}
                  value={select.id}
                  addonAfter="ID"
                  className={!isMobile ? "pdR" : "pdB"}
                  disabled
                />
              ) : (
                <Input
                  size="middle"
                  placeholder="Past tài khoản"
                  prefix={<NumberOutlined />}
                  value={select.data}
                  addonAfter="user|pass|email"
                  className={!isMobile ? "pdR" : "pdB"}
                  onChange={(e) => {
                    let data = e.target.value.split("|");
                    if (data.length == 3)
                      setState({
                        ...state,
                        select: {
                          ...select,
                          username: data[0],
                          password: data[1],
                          email: data[2],
                          data: e.target.value,
                        },
                      });
                    else
                      setState({
                        ...state,
                        select: { ...select, data: e.target.value },
                      });
                  }}
                />
              )}
              <Input
                size="middle"
                placeholder="Username"
                prefix={<UserOutlined />}
                value={select.username}
                addonAfter="Username"
                disabled
                className={!isMobile ? "pdR" : "pdB"}
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, username: e.target.value },
                  })
                }
              />
              <Input.Password
                size="middle"
                placeholder="Password"
                prefix={<KeyOutlined />}
                value={select.password}
                addonAfter="Password"
                className={!isMobile ? "pdR" : "pdB"}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, password: e.target.value },
                  })
                }
              />
            </Col>
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={24}
              xs={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <UploadImage
                item={"img"}
                product_type={select?.category?.product_type}
                max={1}
                type={"item"}
                folder={select.username ? md5(select.username) : ""}
                initialImg={
                  select?.category?.product_type == 0
                    ? select?.img
                      ? [select?.img]
                      : []
                    : select?.category?.img
                    ? [select?.category?.img]
                    : []
                }
                onChange={(result) => {
                  setState({ ...state, select: { ...select, img: result?.[0]?.url } });
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={24} lg={24} md={24} sm={24} className="pdB">
              <Input
                size="middle"
                placeholder="Thông tin nổi bật"
                prefix={<AimOutlined />}
                value={select.description}
                addonAfter="Thông tin nổi bật"
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, description: e.target.value },
                  })
                }
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <Input
                size="middle"
                placeholder="Email"
                prefix={<MailOutlined />}
                value={select.email}
                addonAfter="Email"
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, email: e.target.value },
                  })
                }
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <Input
                size="middle"
                placeholder="Email change"
                prefix={<MailOutlined />}
                value={select.email_new}
                addonAfter="Email change"
                disabled
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, email_new: e.target.value },
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <p style={{ marginBottom: "4px" }}>Hot Account</p>
              <Select
                defaultValue={select.is_hot}
                value={select.is_hot}
                disabled={select?.category?.product_type == 0 ? false : true}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setState({ ...state, select: { ...select, is_hot: value } });
                }}
              >
                <Option value={true}>
                  <Badge color={"green"} text={"Bật"} />
                </Option>
                <Option value={false}>
                  <Badge color={"red"} text={"Tắt"} />
                </Option>
              </Select>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <p style={{ marginBottom: "4px" }}>Bảo hành</p>
              <Select
                defaultValue={select.is_warranty}
                value={select.is_warranty}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setState({
                    ...state,
                    select: { ...select, is_warranty: value },
                  });
                }}
              >
                <Option value={true}>
                  <Badge color={"green"} text={"Còn bảo hành"} />
                </Option>
                <Option value={false}>
                  <Badge color={"red"} text={"Mất bảo hành"} />
                </Option>
              </Select>
            </Col>
          </Row>

          {/* <Row >
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <p style={{ marginBottom: "4px" }}>Chủ sở hữu</p>
                     <SelectSearch style={{ width: "100%" }} placeholder="Để trống nếu không đổi" mode={"tags"} searchData={(e) => handleSearchData(e)}
                        initialOption={select.owner ? {
                           label: `${select.owner.username}`,
                           value: select.owner._id,
                        } : {}}
                        value={select.owner ? select.owner._id : null}
                        onChange={(value, options) => {
                           if (value && options) {
                              setState({ ...state, select: { ...select, owner: { _id: value, username: options.label } } })
                           }
                           else setState({ ...state, select: { ...select, owner: null } })
                        }}
                     />
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <p style={{ marginBottom: "4px" }}>Trạng thái</p>
                     <Select
                        defaultValue={select.type}
                        value={select.type}
                        style={{ width: "100%", }} onChange={(value) => setState({ ...state, select: { ...select, type: value } })}
                     >
                        <Option value={0}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Đang bán</Option>
                        <Option value={3}><Badge color={"blue"} size="20" style={{ marginRight: 10 }}></Badge>Lưu kho</Option>
                        <Option value={1}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>Đã bán</Option>
                        <Option value={2}><Badge color={"yellow"} size="20" style={{ marginRight: 10 }}></Badge>Đã tặng</Option>


                     </Select>
                  </Col>
               </Row> */}
          <Row>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <p style={{ marginBottom: "4px" }}>Danh mục</p>
              <Select
                defaultValue={select.category?._id}
                // disabled={select.category?.product_type == 0 ? false : true}
                value={select.category?._id}
                style={{ width: "100%" }}
                onChange={(value, options) => {
                  let is_hot = false;
                  if (options.product_type == 1) is_hot = false;
                  else is_hot = select.is_hot;
                  setState({
                    ...state,
                    select: { ...select, is_hot, category: { ...options } },
                  });
                }}
              >
                {categoryList && categoryList.length > 0
                  ? categoryList.map((category) =>
                      category?.product_type == 0 ? (
                        <Option value={category._id} {...category}>
                          {category.name}
                        </Option>
                      ) : null
                    )
                  : null}
              </Select>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <p style={{ marginBottom: "4px" }}>Loại</p>
              <Select
                showArrow={null}
                addonAfter="Loại"
                defaultValue={select?.category?.product_type}
                value={select?.category?.product_type}
                disabled
                style={{ width: "100%" }}
                //  onChange={(value) => {
                //    setState({ ...state, select: { ...select, product_type: value } })
                // }}
              >
                <Option value={0}>Acc Vip</Option>
                {/* <Option value={1}>Acc Random</Option> */}
              </Select>
            </Col>
          </Row>

          <Row>
            <Col xl={12} lg={12} md={24} sm={24}>
              <Select
                placeholder="Lựa chọn rank"
                defaultValue={select.rank}
                value={select.rank}
                className={!isMobile ? "pdR" : "pdB"}
                allowClear
                onChange={(value) => {
                  setState({ ...state, select: { ...select, rank: value } });
                }}
              >
                <Option value={"Sắt"}>Sắt</Option>
                <Option value={"Bạc"}>Bạc</Option>
                <Option value={"Vàng"}>Vàng</Option>
                <Option value={"Bạch Kim"}>Bạch Kim</Option>
                <Option value={"Lục Bảo"}>Lục Bảo</Option>
                <Option value={"Kim Cương"}>Kim Cương</Option>
                <Option value={"Cao Thủ"}>Cao Thủ</Option>
                <Option value={"Đại Cao Thủ"}>Đại Cao Thủ</Option>
                <Option value={"Thách Đấu"}>Thách Đấu</Option>
                <Option value={"Tối Cao"}>Tối Cao</Option>
              </Select>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <Select
                placeholder="Lựa chọn khung"
                defaultValue={select.khung}
                value={select.khung}
                className={!isMobile ? "pdR" : "pdB"}
                allowClear
                onChange={(value) => {
                  setState({ ...state, select: { ...select, khung: value } });
                }}
              >
                <Option value={"Sắt"}>Sắt</Option>
                <Option value={"Bạc"}>Bạc</Option>
                <Option value={"Vàng"}>Vàng</Option>
                <Option value={"Bạch Kim"}>Bạch Kim</Option>
                <Option value={"Lục Bảo"}>Lục Bảo</Option>
                <Option value={"Kim Cương"}>Kim Cương</Option>
                <Option value={"Cao Thủ"}>Cao Thủ</Option>
                <Option value={"Đại Cao Thủ"}>Đại Cao Thủ</Option>
                <Option value={"Thách Đấu"}>Thách Đấu</Option>
                <Option value={"Tối Cao"}>Tối Cao</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} md={24} sm={24}>
              <Select
                placeholder="Lựa chọn rank Huyền Thoại"
                defaultValue={select.rank_ht}
                value={select.rank_ht}
                className={!isMobile ? "pdR" : "pdB"}
                allowClear
                onChange={(value) => {
                  setState({ ...state, select: { ...select, rank_ht: value } });
                }}
              >
                {RANK_LENGEND.map((ele, index) => {
                  return (
                    <Option value={ele} key={`${ele}-${index}`}>
                      {ele}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24}>
              <Select
                placeholder="Lựa chọn khung Huyền Thoại"
                defaultValue={select.khung_ht}
                value={select.khung_ht}
                className={!isMobile ? "pdR" : "pdB"}
                allowClear
                onChange={(value) => {
                  setState({
                    ...state,
                    select: { ...select, khung_ht: value },
                  });
                }}
              >
                {FRAME_RANK_LENGEND.map((ele, index) => {
                  return (
                    <Option value={ele} key={`${ele}-${index}`}>
                      {ele}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Champ"
                prefix={<CrownOutlined />}
                value={select.champ}
                addonAfter="Champ"
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, champ: e.target.value },
                  })
                }
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Level"
                prefix={<CrownOutlined />}
                value={select.level}
                addonAfter="Level"
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, level: e.target.value },
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Skin Đỏ"
                prefix={<CrownOutlined />}
                value={select.yellow_skin}
                disabled={
                  select?.category?.type == 0 &&
                  select?.category?.product_type == 0
                    ? false
                    : true
                }
                addonAfter="Vàng"
                defaultValue={0}
                onChange={(e) => {
                  setState({
                    ...state,
                    select: {
                      ...select,
                      yellow_skin: e.target.value,
                      skin:
                        Number(select.orange_skin || 0) +
                        Number(select.purple_skin || 0) +
                        Number(select.normal_skin || 0) +
                        Number(e.target.value),
                    },
                  });
                }}
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Skin Đỏ"
                prefix={<CrownOutlined />}
                value={select.orange_skin}
                disabled={
                  select?.category?.type == 0 &&
                  select?.category?.product_type == 0
                    ? false
                    : true
                }
                addonAfter="Cam"
                defaultValue={0}
                onChange={(e) => {
                  setState({
                    ...state,
                    select: {
                      ...select,
                      orange_skin: e.target.value,
                      skin:
                        Number(select.yellow_skin || 0) +
                        Number(select.purple_skin || 0) +
                        Number(select.normal_skin || 0) +
                        Number(e.target.value),
                    },
                  });
                }}
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Skin Xanh"
                prefix={<CrownOutlined />}
                value={select.purple_skin}
                defaultValue={0}
                disabled={
                  select?.category?.type == 0 &&
                  select?.category?.product_type == 0
                    ? false
                    : true
                }
                addonAfter="Tím"
                onChange={(e) =>
                  setState({
                    ...state,
                    select: {
                      ...select,
                      purple_skin: e.target.value,
                      skin:
                        Number(select.yellow_skin || 0) +
                        Number(select.orange_skin || 0) +
                        Number(select.normal_skin || 0) +
                        Number(e.target.value),
                    },
                  })
                }
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Skin Thường"
                prefix={<CrownOutlined />}
                value={select.normal_skin}
                addonAfter="Thường"
                defaultValue={0}
                disabled={
                  select?.category?.type == 0 &&
                  select?.category?.product_type == 0
                    ? false
                    : true
                }
                onChange={(e) =>
                  setState({
                    ...state,
                    select: {
                      ...select,
                      normal_skin: e.target.value,
                      skin:
                        Number(select.yellow_skin || 0) +
                        Number(select.orange_skin || 0) +
                        Number(select.purple_skin || 0) +
                        Number(e.target.value),
                    },
                  })
                }
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Skin"
                prefix={<CrownOutlined />}
                value={select.skin}
                addonAfter="Skin"
                defaultValue={0}
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, skin: e.target.value },
                  })
                }
              />
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Skin"
                value={select.season}
                addonAfter="Mùa giải"
                defaultValue={0}
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, season: e.target.value },
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <Input.Group compact>
                <NumericInput
                  size="middle"
                  placeholder="Tạm tính"
                  prefix={<TagOutlined />}
                  value={select.price_maybe}
                  disabled
                  style={{
                    width: "calc(100% - 100px)",
                  }}
                  onChange={(e) =>
                    setState({
                      ...state,
                      select: { ...select, price_maybe: e.target.value },
                    })
                  }
                />
                <Button
                  style={{
                    width: "100px",
                  }}
                  onClick={handleSumPrice}
                  type="primary"
                >
                  Tạm tính
                </Button>
              </Input.Group>
            </Col>
            <Col
              xl={12}
              lg={12}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
              <NumericInput
                size="middle"
                placeholder="Giá tiền"
                prefix={<TagOutlined />}
                value={select.price}
                addonAfter="Giá tiền"
                onChange={(e) =>
                  setState({
                    ...state,
                    select: { ...select, price: e.target.value },
                  })
                }
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          header="Ảnh tài khoản"
          key={2}
          extra={<p>Tổng ảnh: {select?.img_list?.length}</p>}
        >
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              className={!isMobile ? "pdR" : "pdB"}
            >
               <UploadImage
                product_type={select?.category?.product_type}
                type={"item"}
                folder={select.username ? md5(select.username) : ""}
                item={"img"}
                max={50}
                initialImg={select?.img_list}
                onChange={(files) => {
                  setState({
                    ...state,
                    select:{...select,
                    img_list:files?.length > 0 ? files?.map(f => f.url) : []}
                  })
                }}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Modal>
  );
};
export default CategoryView;
