// router.post("/account/add_account", auth, accounts.add_account);
// router.post('/account/change_account_info', auth_valid, accounts.change_account_info)
// router.post("/account/update_account", auth, accounts.update_account);
// router.post("/account/add_account_random", auth, accounts.add_account_random);
// router.post("/account/delete_account", auth, accounts.delete_account);
// router.post("/account/get_accounts", auth, accounts.get_accounts);
// router.post("/account/get_accounts_by_admin", auth, accounts.get_accounts_by_admin);
// router.post("/account/get_accounts_owner", auth, accounts.get_accounts_owner);
// router.post("/account/buy_account", auth, accounts.buy_account);


export const ADD_ACCOUNT = "account/ADD_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "account/ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_ERROR = "account/ADD_ACCOUNT_ERROR";

export const CHANGE_ACCOUNT_INFO = "account/CHANGE_ACCOUNT_INFO";
export const CHANGE_ACCOUNT_INFO_SUCCESS = "account/CHANGE_ACCOUNT_INFO_SUCCESS";
export const CHANGE_ACCOUNT_INFO_ERROR = "account/CHANGE_ACCOUNT_INFO_ERROR";

export const CANCEL_REQUEST_CHANGE_ACCOUNT_INFO = "account/CANCEL_REQUEST_CHANGE_ACCOUNT_INFO";
export const CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_SUCCESS = "account/CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_SUCCESS";
export const CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_ERROR = "account/CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_ERROR";


export const UPDATE_ACCOUNT = "account/UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_SUCCESS = "account/UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_ERROR = "account/UPDATE_ACCOUNT_ERROR";

export const ADD_ACCOUNT_RANDOM = "account/ADD_ACCOUNT_RANDOM";
export const ADD_ACCOUNT_RANDOM_SUCCESS = "account/ADD_ACCOUNT_RANDOM_SUCCESS";
export const ADD_ACCOUNT_RANDOM_ERROR = "account/ADD_ACCOUNT_RANDOM_ERROR";

export const DELETE_ACCOUNT = "account/DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "account/DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "account/DELETE_ACCOUNT_ERROR";

export const GET_ACCOUNT_BY_CATEGORY = "account/GET_ACCOUNT_BY_CATEGORY";
export const GET_ACCOUNT_BY_CATEGORY_SUCCESS = "account/GET_ACCOUNT_BY_CATEGORY_SUCCESS";
export const GET_ACCOUNT_BY_CATEGORY_ERROR = "account/GET_ACCOUNT_BY_CATEGORY_ERROR";

export const GET_ACCOUNT_BY_ADMIN = "account/GET_ACCOUNT_BY_ADMIN";
export const GET_ACCOUNT_BY_ADMIN_SUCCESS = "account/GET_ACCOUNT_BY_ADMIN_SUCCESS";
export const GET_ACCOUNT_BY_ADMIN_ERROR = "account/GET_ACCOUNT_BY_ADMIN_ERROR";

export const GET_ACCOUNT_OWNER = "account/GET_ACCOUNT_OWNER";
export const GET_ACCOUNT_OWNER_SUCCESS = "account/GET_ACCOUNT_OWNER_SUCCESS";
export const GET_ACCOUNT_OWNER_ERROR = "account/GET_ACCOUNT_OWNER_ERROR";

export const BUY_ACCOUNT = "account/BUY_ACCOUNT";
export const BUY_ACCOUNT_SUCCESS = "account/BUY_ACCOUNT_SUCCESS";
export const BUY_ACCOUNT_ERROR = "account/BUY_ACCOUNT_ERROR";

export const GET_HISTORYS = "account/GET_HISTORYS";
export const GET_HISTORYS_SUCCESS = "account/GET_HISTORYS_SUCCESS";
export const GET_HISTORYS_ERROR = "account/GET_HISTORYS_ERROR";

export const UPDATE_HISTORYS = "account/UPDATE_HISTORYS";
export const UPDATE_HISTORYS_SUCCESS = "account/UPDATE_HISTORYS_SUCCESS";
export const UPDATE_HISTORYS_ERROR = "account/UPDATE_HISTORYS_ERROR";

export const DELETE_HISTORYS = "account/DELETE_HISTORYS";
export const CONFIRM_CHANGE_PROFILE = "account/CONFIRM_CHANGE_PROFILE";
export const DELETE_HISTORYS_SUCCESS = "account/DELETE_HISTORYS_SUCCESS";
export const DELETE_HISTORYS_ERROR = "account/DELETE_HISTORYS_ERROR";


export const RESET_ACCOUNT = "account/RESET_ACCOUNT";
export const RESET_ACCOUNT_SUCCESS = "account/RESET_ACCOUNT_SUCCESS";
export const RESET_ACCOUNT_ERROR = "account/RESET_ACCOUNT_ERROR";

export const GET_ACCOUNT_PRICE = "account/GET_ACCOUNT_PRICE";
export const GET_ACCOUNT_PRICE_SUCCESS = "account/GET_ACCOUNT_PRICE_SUCCESS";
export const GET_ACCOUNT_PRICE_ERROR = "account/GET_ACCOUNT_PRICE_ERROR";

export const GET_ACCOUNT_INFO = "account/GET_ACCOUNT_INFO";
export const GET_ACCOUNT_INFO_SUCCESS = "account/GET_ACCOUNT_INFO_SUCCESS";
export const GET_ACCOUNT_INFO_ERROR = "account/GET_ACCOUNT_INFO_ERROR";