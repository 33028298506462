import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";

export function* add_account(action) {
  try {
    const { data, status } = yield call(services.add_account, action.payload);
    yield put({
      type: types.ADD_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status} = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.ADD_ACCOUNT_ERROR,
          payload: { msg: data?.msg ?? "Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.ADD_ACCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* change_account_info(action) {
  try {
    const { data, status } = yield call(
      services.change_account_info,
      action.payload
    );
    yield put({
      type: types.CHANGE_ACCOUNT_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.CHANGE_ACCOUNT_INFO_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.CHANGE_ACCOUNT_INFO_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}

export function* cancel_request_change_account_info(action) {
  try {
    const { data, status } = yield call(
      services.cancel_request_change_account_info,
      action.payload
    );
    yield put({
      type: types.CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.CANCEL_REQUEST_CHANGE_ACCOUNT_INFO_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}

export function* update_account(action) {
  try {
    const { data, status } = yield call(
      services.update_account,
      action.payload
    );
    yield put({
      type: types.UPDATE_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.UPDATE_ACCOUNT_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.UPDATE_ACCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* add_account_random(action) {
  try {
    const { data, status } = yield call(
      services.add_account_random,
      action.payload
    );
    yield put({
      type: types.ADD_ACCOUNT_RANDOM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.ADD_ACCOUNT_RANDOM_ERROR,
          payload: { msg:data?.msg?? "Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.ADD_ACCOUNT_RANDOM_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* delete_account(action) {
  try {
    const { data, status } = yield call(
      services.delete_account,
      action.payload
    );
    yield put({
      type: types.DELETE_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.DELETE_ACCOUNT_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.DELETE_ACCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* get_accounts(action) {
  try {
    const { data, status } = yield call(services.get_accounts, action.payload);
    yield put({
      type: types.GET_ACCOUNT_BY_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.GET_ACCOUNT_BY_CATEGORY_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.GET_ACCOUNT_BY_CATEGORY_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* get_accounts_by_admin(action) {
  try {
    const { data, status } = yield call(
      services.get_accounts_by_admin,
      action.payload
    );
    yield put({
      type: types.GET_ACCOUNT_BY_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.GET_ACCOUNT_BY_ADMIN_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.GET_ACCOUNT_BY_ADMIN_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* get_accounts_owner(action) {
  try {
    const { data, status } = yield call(
      services.get_accounts_owner,
      action.payload
    );
    yield put({
      type: types.GET_ACCOUNT_OWNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.GET_ACCOUNT_OWNER_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.GET_ACCOUNT_OWNER_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* buy_account(action) {
  try {
    const { data, status } = yield call(services.buy_account, action.payload);
    yield put({
      type: types.BUY_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.BUY_ACCOUNT_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.BUY_ACCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* get_historys(action) {
  try {
    const { data, status } = yield call(services.get_historys, action.payload);
    yield put({
      type: types.GET_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.GET_HISTORYS_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.GET_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* delete_history(action) {
  try {
    const { data, status } = yield call(
      services.delete_history,
      action.payload
    );
    yield put({
      type: types.DELETE_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.DELETE_HISTORYS_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.DELETE_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}

export function* confirm_changeProfile(action) {
  try {
    const { data, status } = yield call(
      services.confirm_change,
      action.payload
    );
    yield put({
      type: types.DELETE_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.DELETE_HISTORYS_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.DELETE_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}

export function* update_history(action) {
  try {
    const { data, status } = yield call(
      services.update_history,
      action.payload
    );
    yield put({
      type: types.UPDATE_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.UPDATE_HISTORYS_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.UPDATE_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* get_account_price(action) {
  try {
    const { data, status } = yield call(
      services.get_account_price,
      action.payload
    );
    yield put({
      type: types.GET_ACCOUNT_PRICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.GET_ACCOUNT_PRICE_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.GET_ACCOUNT_PRICE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export function* get_account_info(action) {
  try {
    const { data, status } = yield call(
      services.get_account_info,
      action.payload
    );
    yield put({
      type: types.GET_ACCOUNT_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response;
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false,
          },
        });
      else
        yield put({
          type: types.GET_ACCOUNT_INFO_ERROR,
          payload: { msg: data?.msg??"Không thể kết nối server", status: false },
        });
    } else
      yield put({
        type: types.GET_ACCOUNT_INFO_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false,
        },
      });
  }
}
export default function* accountSaga() {
  yield takeLatest(types.ADD_ACCOUNT, add_account);
  yield takeLatest(types.CHANGE_ACCOUNT_INFO, change_account_info);
  yield takeLatest(
    types.CANCEL_REQUEST_CHANGE_ACCOUNT_INFO,
    cancel_request_change_account_info
  );
  yield takeLatest(types.UPDATE_ACCOUNT, update_account);
  yield takeLatest(types.ADD_ACCOUNT_RANDOM, add_account_random);
  yield takeLatest(types.DELETE_ACCOUNT, delete_account);
  yield takeLatest(types.GET_ACCOUNT_BY_CATEGORY, get_accounts);
  yield takeLatest(types.GET_ACCOUNT_BY_ADMIN, get_accounts_by_admin);
  yield takeLatest(types.GET_ACCOUNT_OWNER, get_accounts_owner);
  yield takeLatest(types.BUY_ACCOUNT, buy_account);
  yield takeLatest(types.GET_HISTORYS, get_historys);
  yield takeLatest(types.UPDATE_HISTORYS, update_history);
  yield takeLatest(types.DELETE_HISTORYS, delete_history);
  yield takeLatest(types.CONFIRM_CHANGE_PROFILE, confirm_changeProfile);
  yield takeLatest(types.GET_ACCOUNT_PRICE, get_account_price);
  yield takeLatest(types.GET_ACCOUNT_INFO, get_account_info);
}
