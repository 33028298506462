import { httpClient } from '../../utils/Api';

export const uploadFileService = (data) => {
  const url = `${process.env.REACT_APP_API_URL}/media/upload-s3`;
  return httpClient.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
