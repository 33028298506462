import {
  Button,
  Col,
  Empty,
  Image,
  Pagination,
  Row,
  Space,
  Spin,
  Typography,
  message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { getGuides } from '../../modules/general/service';
import { ROUTE_PATH } from '../../constants/router.constants';
import { isMobile } from 'react-device-detect';

export default function ClientGuide() {
  const [loading, setLoading] = useState(false);
  const [dataGuides, setDataGuides] = useState([]);
  const [total, setTotal] = useState(0);
  const refFilter = useRef({ page: 1, limit: 10 });
  const history = useHistory();

  const onGetGuides = async (payload) => {
    setLoading(true);
    const res = await getGuides(
      payload ?? {
        page: 1,
        limit: 10,
        active: true,
      }
    );
    if (res?.data?.status) {
      setDataGuides(res?.data?.data);
      setTotal(res?.data?.total);
    } else {
      message.error({
        message: res?.data?.msg ?? 'Lấy hướng dẫn thất bại',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    onGetGuides();
  }, []);

  const onChangePage = (page) => {
    const payload = {
      ...refFilter.current,
      active: true,
      page: page,
    };

    refFilter.current = payload;
    onGetGuides(payload);
  };

  const viewGuide = (id) => history.push(`${ROUTE_PATH.guideView}/${id}`);
  return (
    <Spin spinning={loading}>
      <Space
        direction='vertical'
        size={24}
        style={{ width: '100%', margin: '48px 0' }}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: '28px',
              fontWeight: 600,
              lineHeight: '32px',
              textAlign: 'center',
              color: 'red',
            }}
          >
            Chào mừng bạn đến với trang hướng dẫn của TocChienStore.Vn
          </Typography>
        </div>

        <Row gutter={[32, 32]} style={{ overflow: 'hidden' }}>
          {dataGuides?.length > 0 && !loading ? (
            dataGuides.map((guide, key) => {
              return (
                <Col
                  xs={12}
                  sm={8}
                  md={8}
                  lg={6}
                  key={guide._id + key}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Space direction='vertical' size={12}>
                    <Image
                      src='/assets/images/logo-gif-new.gif'
                      preview={false}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />

                    <Typography
                      style={{
                        textAlign: 'center',
                        fontSize: isMobile ? 14 : 16,
                        lineHeight: '24px',
                        color: 'black',
                        fontWeight: 600,
                      }}
                    >
                      {guide.title}
                    </Typography>
                  </Space>
                  <div style={{ display: 'flex', alignItems: 'end', flex: 1 }}>
                    <Button
                      style={{ width: '100%', height: 32, marginTop: 12 }}
                      className='color1'
                      onClick={() => viewGuide(guide?.slug)}
                    >
                      Xem hướng dẫn
                    </Button>
                  </div>
                </Col>
              );
            })
          ) : (
            <Row align='middle' justify='center' style={{ width: '100%' }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description='Không có hướng dẫn'
              />
            </Row>
          )}
        </Row>
        {total > refFilter.current.limit && (
          <Row align='middle' justify='center' style={{ width: '100%' }}>
            <Pagination
              onChange={onChangePage}
              total={total}
              pageSize={refFilter.current.limit}
              current={refFilter.current.page}
            />
          </Row>
        )}
      </Space>
    </Spin>
  );
}
