import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";
// router.post("/wheel/add_wheel", auth, wheels.add_wheel);
// router.post("/wheel/update_wheel", auth, wheels.update_wheel);
// router.post("/wheel/play", auth, wheels.play);
// router.post("/wheel/get_wheel", auth, wheels.get_wheel);

export function* add_wheel(action) {
  try {
    const { data, status } = yield call(services.add_wheel, action.payload);
    yield put({
      type: types.ADD_WHEEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.ADD_WHEEL_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.ADD_WHEEL_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_wheel(action) {
  try {
    const { data, status } = yield call(services.update_wheel, action.payload);
    yield put({
      type: types.UPDATE_WHEEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_WHEEL_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_WHEEL_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* play_wheel(action) {
  try {
    const { data, status } = yield call(services.play_wheel, action.payload);
    yield put({
      type: types.PLAY_WHEEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.PLAY_WHEEL_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.PLAY_WHEEL_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_wheel(action) {
  try {

    const { data, status } = yield call(services.get_wheels, action.payload)
    yield put({
      type: types.GET_WHEEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_WHEEL_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_WHEEL_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_wheel(action) {
  try {
    const { data, status } = yield call(services.delete_wheel, action.payload);
    yield put({
      type: types.DELETE_WHEEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_WHEEL_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_WHEEL_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_historys(action) {
  try {
    const { data, status } = yield call(services.get_historys, action.payload);
    yield put({
      type: types.GET_HISTORYS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_HISTORYS_ERROR,
        payload: {msg:data?.msg??"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_HISTORYS_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* wheelSaga() {
  yield takeLatest(types.ADD_WHEEL, add_wheel);
  yield takeLatest(types.UPDATE_WHEEL, update_wheel);
  yield takeLatest(types.PLAY_WHEEL, play_wheel);
  yield takeLatest(types.GET_WHEEL, get_wheel);
  yield takeLatest(types.DELETE_WHEEL, delete_wheel);
  yield takeLatest(types.GET_HISTORYS, get_historys);

}
