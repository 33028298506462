import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Spin, Upload } from 'antd';
import { debounce } from 'lodash';
import md5 from 'md5';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { beforeUpload, convertUrlToFileList, getUrls } from './functions';
const UploadImage = (props) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    initialImg,
    folder,
    type,
    product_type,
    ref: refUpload,
    max,
    text,
    onChange,
  } = props;

  const singleSrc = useMemo(() => {
    if (files?.length > 0) {
      return files[0]?.url;
    }
    return `${process.env.REACT_APP_IMAGE_ENDPOINT}${
      folder ? `${md5(folder)}/` : ''
    }${initialImg[0]}`;
  }, [files, initialImg, folder]);

  console.log(singleSrc,'singleSrc');
  const disabled = useMemo(() => {
    if (type === 'item' && (folder === '' || !typeof folder === 'undefined')) {
      return true;
    }
    if (product_type === 1) {
      return true;
    }
    return false;
  }, [folder, type, product_type]);

  const handleDelete = async (fileSelect) => {
    const newF = files?.filter((f) => f.uid !== fileSelect.uid) || [];
    setFiles(newF);
    onChange(newF);
  };
  const onChangeImage = async (info) => {
    if (info.file.status === 'removed') return;
    setIsLoading(true);
    const urls = await getUrls(info?.fileList);
    let formatFiles
    if(max !== 1){
      formatFiles = [...convertUrlToFileList(urls), ...files];
    }else{
      formatFiles = convertUrlToFileList(urls)
    }
    onChange(formatFiles);
    setIsLoading(false);
  };

  const initialStateUpload = {
    fileList: files,
    listType: max === 1 ? 'picture-card' : 'picture',
    className:
      max === 1
        ? 'avatar-uploader'
        : isMobile
        ? 'upload-list-inline_mobile'
        : 'upload-list-inline',
    showUploadList: max !== 1,
    maxCount: max,
    multiple: max !== 1,
    accept: '.png,.jpeg,.jpg,.gif',
    name: 'files',
    beforeUpload: async (file) => {
      beforeUpload(file, files, max);
    },
    onRemove(file) {
      handleDelete(file);
    },
  };
  // first step preview files
  useEffect(() => {
    if(initialImg){
      console.log(initialImg,'initialImg');
      const f = convertUrlToFileList(initialImg);
      setFiles(f);
    }
  },[initialImg])
  return (
    <Spin spinning={isLoading}>
      <Upload
        style={{ height: 10 }}
        {...initialStateUpload}
        disabled={disabled}
        onChange={debounce(onChangeImage, 100)}
        customRequest={() => {}}
      >
        {max === 1 && singleSrc && (
          <Avatar
            src={singleSrc}
            size='large'
            style={{
              width: '60px',
              height: '60px',
            }}
          />
        )}
        {max === 1 && !singleSrc && (
          <UploadButton loading={isLoading} text={text} />
        )}
        {max !== 1 && (
          <Button
            disabled={disabled}
            type='dashed'
            style={{ marginRight: '-10px', width: '100%' }}
            ref={refUpload}
            danger
            icon={<UploadOutlined />}
          >
            Chọn ảnh
          </Button>
        )}
      </Upload>
    </Spin>
  );
};
export default UploadImage;

const UploadButton = (loading, text) => {
  return (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 2,
        }}
      >
        {text ? text : 'Ảnh Bìa'}
      </div>
    </div>
  );
};
