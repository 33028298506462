import { Drawer } from 'antd';
import { useState } from 'react';

const DrawerProfile = ({ children, node }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div onClick={() => setOpen(true)}>{node}</div>
      <Drawer
        title='Menu'
        placement={'left'}
        width={'100%'}
        onClose={() => setOpen(false)}
        open={open}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          className='drawer_profile'
          onClick={() => setOpen(false)}
        >
          {children}
        </div>
      </Drawer>
    </>
  );
};

export default DrawerProfile;
