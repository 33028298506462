/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Button, Checkbox, Form, Input, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  KeyOutlined,
  LoginOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { login, reset_user } from '../modules/user/actions';
import { ROUTE_PATH } from '../constants/router.constants';

export const REG_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const _user = useSelector(({ user }) => user.user);
  const _me = useSelector(({ user }) => user.me);

  const initialState = {
    email: localStorage.getItem('tcstore_email')
      ? localStorage.getItem('tcstore_email')
      : '',
    password: localStorage.getItem('tcstore_password')
      ? localStorage.getItem('tcstore_password')
      : '',
    remember: true,
  };
  const onFinish = (values) => {
    if (values.remember) {
      localStorage.setItem('tcstore_email', values.email);
      localStorage.setItem('tcstore_password', values.password);
    } else {
      localStorage.removeItem('tcstore_email');
      localStorage.removeItem('tcstore_password');
    }
    dispatch(
      login({
        device: 'web',
        username: values.email.trim(),
        password: values.password.trim(),
      })
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (_me?.status === true) {
      history.push('/');
    }
    return () => {
      dispatch(reset_user({ remove: ['register', 'user'] }));
    };
  }, []);
  return (
    <Form onFinish={onFinish} initialValues={initialState}>
      <div className='center' style={{ width: isMobile ? '85%' : null }}>
        <div>
          <div style={{ textAlign: 'center', padding: 10 }}>
            <img
              width='200'
              src='https://images.viblo.asia/a87852d0-d60c-4a7c-ae42-0bfb6679ecb3.gif'
            />
            <h3>Đăng Nhập Hệ Thống</h3>
          </div>
          <Input placeholder='Device' style={{ display: 'none' }} />
          <Form.Item
            name={'email'}
          >
            <Input prefix={<UserOutlined />} placeholder='Email/Phone' />
          </Form.Item>
          <Form.Item
            name={'password'}
            rules={[{ required: true, message: 'Nhập mật khẩu' }]}
          >
            <Input.Password
              prefix={<KeyOutlined />}
              type='password'
              placeholder='Mật khẩu'
            />
          </Form.Item>
          <Row align='center' justify='space-between'>
            <Form.Item name={'remember'} noStyle valuePropName='checked'>
              <Checkbox style={{ marginBottom: '20px' }}>
                Ghi nhớ thông tin đăng nhập
              </Checkbox>
            </Form.Item>
            <a href='#' onClick={() => history.push(ROUTE_PATH.forgotPassword)}>
              Quên mật khẩu ?
            </a>
          </Row>

          <br />
        </div>
        <div style={{ width: '100%', paddingBottom: 10 }}>
          <Form.Item noStyle>
            <Button
              icon={<LoginOutlined />}
              style={{ width: '49%', margin: 0, marginRight: '2%' }}
              type='primary'
              // onClick={() => loginUser()}
              loading={_user.isLoading}
              className='color1'
              htmlType='submit'
            >
              Đăng Nhập
            </Button>
          </Form.Item>

          <Button
            icon={<UserAddOutlined />}
            style={{ width: '49%', margin: 0 }}
            type='primary'
            onClick={() => history.push('/signup')}
            className='color2'
          >
            Về Đăng Ký
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default SignIn;
