import { Button, Modal, Row, Col, Avatar, Space, Table, Card } from "antd"
import { useEffect, useState } from "react"
import { LogoutOutlined, AlertOutlined, QuestionCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { curencyFormat, genUsername, winLabel } from "../utils/help";
import { isMobile } from "react-device-detect";
import Seeding from "./Seeding";
import { useHistory } from "react-router-dom";

const Wheel = (props) => {
    const history = useHistory();

    const {onClick: handlePlay, imgRotote, img_wheel, price, description} = props
    const [historyData, setHistoryData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleGenData = ()=>{
        let data = []
       for (let index = 0; index < 100; index++) {
        let username = genUsername();
        username = username.substring(0, 10) + "****"
         data.push({
            username: username,
            description: `Đã nhận được phần thưởng: ${winLabel[Math.floor(Math.random() * winLabel.length )]}`
         })
       }
       setHistoryData(data)
    }
    useEffect(()=>{
        handleGenData()
    },[])
  
    let columns = [
        {
            title: '#',
            key: 'index',
            width: 50,
            align: "center",
            render: (text, record, index) =>  index + 1,
         },
        {
           title: 'Người chơi',
           dataIndex: 'username',
           key: 'username',
           width: 160,
           ellipsis: true,
           render: (text) => {
              return <>
                 <Space>
                 <Avatar size="small" src={"/assets/images/user.png"} />
                    <div style={{ alignItems: "center" }}>
                       <span>
                          {text}
                       </span>
                    </div>
                 </Space>
              </>
           }
        },
        {
            title: 'Phần thưởng',
            key: 'description',
            dataIndex: 'description',
        
            render: (text) => text
         },
     ];
    const optionTable = {
        bordered: true,
        size: 'small',
        showHeader: true,
        columns:  columns,
        dataSource: [...historyData],
        pagination: { defaultPageSize: 10 },
        bordered: false,
        scroll: isMobile ? {} : { y: "420px", x: "100%" },
     };
    return (
        <Card style={{ marginBottom: 30, borderRadius: 10 }}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} style={{textAlign:"center"}}>
               
                    <div class="wheel-wrapper">
                        <a className="wheel-pointer" id="start" onClick={()=> handlePlay()}></a>
                        <img style={imgRotote} src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${img_wheel}`} className="wheel" />
                        <br />
                    </div>

                   <Space>
                     <Button icon={<AlertOutlined />} className="color2" style={{marginTop:20}} onClick={() => {
                        handlePlay()
                    }}>Quay Ngay | Giá mỗi lượt: {curencyFormat(price)}</Button>
                   </Space>
                   
               
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24} style={{marginTop:10}}>
            <Space direction="horizontal" style={{ width: '100%', marginBottom:15, justifyContent: isMobile ? "center":"right" }}>
                    <Button icon={<QuestionCircleOutlined />} onClick={showModal} type="primary" danger className="color1">Thể Lệ</Button>
                    <Button icon={<HistoryOutlined />} onClick={()=>{
                        history.push("/profile/wheels")
                    }}  type="primary" danger className="color2">Lịch Sử Vòng Quay</Button>

                </Space>
               <Table bordered={false}  {...optionTable}/>
            </Col>
        </Row>
       
         <Modal title={`Thể lệ trò chơi`}
         visible={isModalOpen}
         style={{ top: 20 }}
         destroyOnClose={true}
         onCancel={() => setIsModalOpen(false)}
         width={isMobile ? "90%" : "500px"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
           
            <Button icon={<LogoutOutlined />} onClick={() =>setIsModalOpen(false)} type="primary" className='color2'>Đóng</Button>
         </Space>}
      >
        {description.split(/;?\r\n|;?\n|;?\r/).map(item=>{
            return (<>
            <span>{item}</span><br/>
            </>)
        })}
      </Modal>
      </Card>
    )
}
export default Wheel