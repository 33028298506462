export const RANK_LENGEND = ['Không Có', 'Chiến Binh', 'Tướng Soái', 'Vệ Binh', 'Chinh Phục', 'Bất Tử', 'Huyền Thoại'];
export const FRAME_RANK_LENGEND = [
  'Không Có',
  'Chiến Binh',
  'Tướng Soái',
  'Vệ Binh',
  'Chinh Phục',
  'Bất Tử',
  'Huyền Thoại',
];
