export const API_PATH = {
  GET_ACCOUNT_PROVIDER: '/account/provider/get_accounts_by_provider',
  ADD_ACCOUNT_PROVIDER: '/account/provider/add_account',
  DELETE_ACCOUNT_PROVIDER: '/account/provider/delete_account',
  ADD_RANDOM_ACCOUNT_PROVIDER: '/account/provider/add_account_random',
  UPDATE_ACCOUNT_PROVIDER: '/account/provider/update_account',
  CONFIRM_ACCOUNT_PROVIDER: (id) => `/account/provider/confirm_acccount/${id}`,

  NOTIFICATION_CREATE: '/notification',
  NOTIFICATION_UPDATE: '/notification/update',
  NOTIFICATION_DELETE: '/notification/delete',
  NOTIFICATION_SEARCH: '/notification/search',

  GUIDE_CREATE: '/guide',
  GUIDE_UPDATE: '/guide/update',
  GUIDE_DELETE: '/guide/delete',
  GUIDE_SEARCH: '/guide/search',
  GUIDE_DETAIL: '/guide/detail',
};
